import VideoModal from '@/components/modals/video-modal/VideoModal';
import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultCampaignMappingFilters } from '@/components/filter-builder/models/AlFilterModel';
import { VideoUrl } from '@/config/urls';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { usePaywall } from '@/modules/plans/hooks/usePaywall';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { Alert, Button } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  campaignMappingService,
  createCampaignMappingQueryKey,
  invalidateProfile_campaignMappingQueryKeys,
} from '../api/campaign-mapping-service';
import CampaignMappingSelectionActionsBar from '../components/CampaignMappingActionsBar';
import CampaignMappingFilterBar from '../components/CampaignMappingFilterBar';
import CampaignMappingTable from '../components/campaign-mapping-table/CampaignMappingTable';
import { CAMPAIGN_MAPPING_CONTEXT_KEY, CampaignMappingProvider } from '../contexts/CampaignMappingContext';
import { CampaignMappingModel } from '../models/CampaignMappingModel';
import useTutorialModal from '@/components/modals/video-modal/useVideoModal';
import { UserSettingKey } from '@/modules/users';

let isPaywallDisplayedBefore = false;

interface CampaignMappingPageProps {}

const CampaignMappingPage: FunctionComponent<CampaignMappingPageProps> = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { activeTeam, activeProfile, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { paywallModalElement, showPaywallWithDelay } = usePaywall({
    message: 'Upgrade to Pro to Access the Campaign Mapping Tool Suite',
    videoUrl: VideoUrl.KW_HARVESTING,
  });

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(CAMPAIGN_MAPPING_CONTEXT_KEY, activeProfile?.id ?? '', getDefaultCampaignMappingFilters());
  });
  const [selectedCampaignMappings, setSelectedCampaignMappings] = useState<CampaignMappingModel[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const campaignMappingTableGridApiRef = useRef<GridApi<CampaignMappingModel> | null>(null);
  function clearSelections() {
    setSelectedCampaignMappings([]);
    if (campaignMappingTableGridApiRef.current && !campaignMappingTableGridApiRef.current.isDestroyed()) {
      campaignMappingTableGridApiRef.current.deselectAll();
    }
  }

  const {
    data: campaignMappingRowData,
    isLoading: isCampaignMappingRowDataLoading,
    isError: isCampaignMappingRowDataLoadingError,
    error: campaignMappingRowDataLoadingError,
  } = useQuery({
    queryKey: createCampaignMappingQueryKey(activeProfile?.id, filters),
    queryFn: async () => {
      const result = await campaignMappingService.getCampaignMappingData(filters);
      if (result.isSuccess) {
        return result.payload;
      } else {
        // If no mappings found, return empty array
        if (result.httpResponseCode === 404) {
          return [];
        }
        toast.error('Error loading campaign mappings ' + JSON.stringify(result));
        throw new Error('Error loading campaign mappings ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(activeProfile?.id),
  });

  function onCampaignMappingTableGridReady(params: GridReadyEvent) {
    campaignMappingTableGridApiRef.current = params.api;
  }

  useEffect(() => {
    // Can't do in queryFn because it's not called on invalidation
    // Not doing just refetch because invalidation includes also other keys
    clearSelections();
  }, [campaignMappingRowData]);

  // Paywall
  useEffect(() => {
    const paywallDelay = isPaywallDisplayedBefore ? 0 : 10;

    if (hasUserOverMaxAllowedFreeProfiles()) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
    }

    if (isNil(activeTeam)) {
      toast.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canUseCampaignMappingPage) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
      return;
    }
  }, []);

  // Delete all mappings
  const [isLoadingDeleteAll, setIsLoadingDeleteAll] = useState(false);
  async function onDeleteAllConfirmed() {
    if (!campaignMappingRowData) return;

    const ids = campaignMappingRowData.map((row) => row.id).filter((id) => !isNil(id)) as string[]; // ts doesn't understand null removal;
    if (ids.length === 0) return;

    setIsLoadingDeleteAll(true);

    try {
      // When deleting all (no filters applied), deleteAllCampaignMappings is more efficient
      const response =
        filters.length > 0
          ? await campaignMappingService.deleteCampaignMappings(campaignMappingRowData)
          : await campaignMappingService.deleteAllCampaignMappings();

      if (!response.isSuccess) {
        toast.error(response.message);
      } else {
        toast.success(`${ids.length} Campaign Mappings deleted successfully`);
        invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete campaign mapping: ' + error);
    }

    setIsLoadingDeleteAll(false);
  }

  // TUTORIAL MODAL
  const { isTutorialModalOpen, onTutorialModalClose, openTutorialModal } = useTutorialModal(
    UserSettingKey.CAMPAIGN_MAPPING_TUTORIAL_DISPLAY_COUNT,
  );

  // Row count in master table
  const totalMappingsCount = campaignMappingRowData ? campaignMappingRowData.length : 0;
  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={
            <div className="flex flex-row items-center gap-4">
              {t('campaign_mapping_page.header')}
              <Button onClick={() => openTutorialModal()} variant="contained" startIcon={<OndemandVideoIcon />}>
                How to Harvest Keywords & Products Targets
              </Button>
            </div>
          }
          actions={
            <div className="flex flex-row items-center gap-2">
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <TeamSelect />
              <ProfileSyncSelectButton setFilters={setFilters} />
            </div>
          }
        />

        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding>
            <CampaignMappingProvider filters={filters} setFilters={setFilters}>
              <div className="mt-4"></div>
              {campaignMappingRowData && campaignMappingRowData.length == 0 && filters.length == 0 && (
                <Alert className="mb-4" severity="info">
                  No campaign mappings found. Import or create them manually.
                </Alert>
              )}
              <AlErrorBoundary>
                <CampaignMappingFilterBar
                  onDeleteAllConfirmed={campaignMappingRowData && campaignMappingRowData.length > 0 ? onDeleteAllConfirmed : undefined}
                  isDeleteLoading={isLoadingDeleteAll}
                  rowData={campaignMappingRowData}
                />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <CampaignMappingTable
                  rowData={campaignMappingRowData ?? []}
                  isLoading={isCampaignMappingRowDataLoading}
                  selectedCampaignMappings={selectedCampaignMappings}
                  setSelectedCampaignMappings={setSelectedCampaignMappings}
                  campaignMappingLoadingErrorMessage={
                    campaignMappingRowDataLoadingError instanceof Error ? campaignMappingRowDataLoadingError.message : ''
                  }
                  isCampaignMappingLoadingError={isCampaignMappingRowDataLoadingError}
                  onGridReadyCallback={onCampaignMappingTableGridReady}
                  noTopBorderRadius={true}
                />
              </AlErrorBoundary>
            </CampaignMappingProvider>
          </PageLayoutBody>
        )}
      </PageLayout>

      <AlErrorBoundary>
        <CampaignMappingSelectionActionsBar
          selectedCampaignMappings={selectedCampaignMappings}
          setSelectedCampaignMappings={setSelectedCampaignMappings}
          totalMappingsCount={totalMappingsCount}
          onDiscardClicked={clearSelections}
          mappingsTableGridApiRef={campaignMappingTableGridApiRef}
        />
      </AlErrorBoundary>

      <VideoModal
        isOpen={isTutorialModalOpen}
        onClose={onTutorialModalClose}
        url={VideoUrl.KW_HARVESTING}
        title={'How to Harvest Keywords & Products Targets'}
      />

      {paywallModalElement}
    </>
  );
};

export default CampaignMappingPage;
