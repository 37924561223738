import { Environment } from '@/config/Environment';
import { useTranslation } from '@/lib';
import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { CampaignAdType, ChangeCampaignGroupDTO, NewCampaignGroupDTO } from '../../../api/campaign/campaign-contracts';
import { campaignService } from '../../../api/campaign/campaign-service';
import { CampaignGroupModel } from '../../../api/campaign/models/CampaignGroupModel';
import { SelectedCampaignDTO } from '../../../api/campaign/models/CampaignModel';
import { OptimizationPreset, availableOptimizationPresets } from '../../optimization/OptimizerConfig';
import { FlowType } from '@/modules/log-viewing/api/logs-contracts';

interface CreateCampaignGroupProps {
  selectedCampaigns?: SelectedCampaignDTO[];
  existingGroups: CampaignGroupModel[];
  onClose: () => void;
  applyChanges?: (changeGroups: ChangeCampaignGroupDTO[], isNewGroup?: boolean) => void;
  isApplyPending?: boolean;
  onCreateConfirmed: (campaignGroup: CampaignGroupModel) => void;
  flowType: FlowType;
}

export const CreateCampaignGroup: FunctionComponent<CreateCampaignGroupProps> = ({
  selectedCampaigns,
  existingGroups,
  onClose,
  applyChanges,
  isApplyPending = false,
  onCreateConfirmed,
  flowType,
}) => {
  const { t } = useTranslation();
  // const { getCurrencySymbolPosition, getCurrencySymbol } = useFormatting();
  // const currencyPosition = getCurrencySymbolPosition();
  // const currencySymbol = getCurrencySymbol();

  const [adType, setAdType] = useState<CampaignAdType>(
    selectedCampaigns && selectedCampaigns.length > 0 ? selectedCampaigns[0].adType : ('' as CampaignAdType),
  );
  const [groupName, setGroupName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [targetAcos, setTargetACOS] = useState<string>('');
  const [prioritization, setPrioritization] = useState<OptimizationPreset>(OptimizationPreset.NOT_SET);
  // const [bidCeiling, setBidCeiling] = useState<string>('');

  const checkGroupNameExists = (name: string): boolean => {
    return !isNil(existingGroups) && existingGroups.some((group) => group.name === name);
  };

  const handleAdTypeChange = (event: SelectChangeEvent<CampaignAdType>) => {
    setAdType(event.target.value as CampaignAdType);
  };

  const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setGroupName(name);
    setNameError(checkGroupNameExists(name));
  };

  const handleTargetACOSChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setTargetACOS(value);
    }
  };

  // const handleBidCeilingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = event.target.value;
  //   if (!isNaN(Number(value))) {
  //     setBidCeiling(value);
  //   }
  // };

  const handlePrioritizationChange = (event: SelectChangeEvent<OptimizationPreset>) => {
    setPrioritization(event.target.value as OptimizationPreset);
  };

  const handleCreate = () => {
    const numberTargetAcos = Number(targetAcos);
    //const numberBidCeiling = Number(bidCeiling);

    if (isNil(adType)) {
      toast.error('Please select an ad type');
      return;
    }

    const groupData: NewCampaignGroupDTO = {
      name: groupName,
      ad_type: adType,
      tacos: !isNaN(numberTargetAcos) && targetAcos != '' ? numberTargetAcos / 100 : undefined, // always in fractions when not displaying
      preset: !isEmpty(prioritization) && prioritization != OptimizationPreset.NOT_SET ? prioritization : undefined,
      bid_ceiling: undefined, // bid_ceiling: !isNaN(numberBidCeiling) && bidCeiling != '' ? numberBidCeiling : undefined,
    };

    createGroup(groupData);

    onClose();
  };

  const { mutate: createGroup, isPending: isLoadingCreateGroup } = useMutation({
    mutationFn: (groupData: NewCampaignGroupDTO) => campaignService.createGroups([groupData], flowType),
    onMutate: () => {
      if (Environment.isDev()) {
        console.log('Creating a new group');
      }
    },
    onSuccess: (res) => {
      if (res.isSuccess) {
        const newGroup = res.payload[0];
        if (selectedCampaigns && selectedCampaigns.length > 0 && applyChanges) {
          const changeGroups: ChangeCampaignGroupDTO[] = selectedCampaigns.map((campaign) => ({
            campaign_id: campaign.id,
            group_id: newGroup.id,
          }));

          applyChanges(changeGroups, true);
        } else {
          toast.success(`Optimization group created successfully`);
        }

        onCreateConfirmed(newGroup);
      } else {
        toast.error(`Did not receive a response from server: ${res.message}`);
      }
    },
  });

  // const getInputProps = (unitType: UnitType | undefined, currencyPosition: CurrencyPosition, currencySymbol: string) => {
  //   if (!unitType) {
  //     return {};
  //   }

  //   if (unitType === UnitType.PERCENTAGE) {
  //     return { endAdornment: <InputAdornment position="end">%</InputAdornment> };
  //   }

  //   if (unitType === UnitType.CURRENCY) {
  //     return currencyPosition === CurrencyPosition.LEFT
  //       ? { startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment> }
  //       : { endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment> };
  //   }

  //   return {};
  // };

  return (
    <>
      <DialogTitle>Create New Optimization Group</DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel>Ad Type</InputLabel>
                <Select
                  value={adType}
                  onChange={handleAdTypeChange}
                  label="Ad Type"
                  disabled={selectedCampaigns && selectedCampaigns.length > 0}
                >
                  {Object.keys(CampaignAdType).map((adType) => (
                    <MenuItem key={adType} value={adType}>
                      {t(`optimizer_page.ad_type.${adType}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                fullWidth
                error={nameError}
                label="Group Name"
                value={groupName}
                onChange={handleGroupNameChange}
                helperText={nameError ? 'This group already exists' : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Target ACOS"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onChange={handleTargetACOSChange}
                value={targetAcos}
                placeholder={targetAcos === '' ? 'Not Set' : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Prioritization</InputLabel>
                <Select
                  value={prioritization}
                  onChange={handlePrioritizationChange}
                  label="Prioritization"
                  className={`${prioritization == OptimizationPreset.NOT_SET ? 'text-gray-400' : ''}`}
                >
                  {availableOptimizationPresets.map((preset) => (
                    <MenuItem key={preset} value={preset}>
                      {t(`optimizer_page.optimization_presets.${preset}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                fullWidth
                label="Bid Ceiling"
                InputProps={getInputProps(UnitType.CURRENCY, currencyPosition, currencySymbol)}
                onChange={handleBidCeilingChange}
                value={bidCeiling}
                placeholder={bidCeiling === '' ? 'Not Set' : ''}
              />
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>

        <Tooltip title={groupName === '' ? 'Group Name not specified' : nameError ? 'Problem with Group Name' : ''}>
          <span>
            <LoadingButton
              onClick={handleCreate}
              loading={isApplyPending || isLoadingCreateGroup}
              loadingPosition="start"
              startIcon={<Check />}
              variant="contained"
              disabled={nameError || groupName === '' || isEmpty(adType)}
            >
              <span>
                Create
                {selectedCampaigns && selectedCampaigns.length > 0
                  ? ` and Assign to ${selectedCampaigns.length} Campaign` + (selectedCampaigns.length > 1 ? 's' : '')
                  : ''}
              </span>
            </LoadingButton>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};
