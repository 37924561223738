import { AlMultiSelectOptionModel } from '@/components/filter-builder/models/AlMultiSelectOptionModel';
import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import AlSelect from '@/components/form/AlSelect';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib';
import { isValidPositiveNumberOrEmptyString } from '@/modules/application/utils';
import {
  AD_GROUP_NEGATIVES,
  CAMPAIGN_NEGATIVES,
  KEYWORD_NEGATIVES,
  NegativeMatchType,
  PRODUCT_TARGET_NEGATIVES,
  negativesArrayToNegativeBoolsObj,
} from '@/modules/negative-targets/api/negative-targets-contracts';
import { CampaignAdType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import useBidLimits from '@/modules/optimizer/components/optimization/bid-limits';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogTitle, Popover, SelectChangeEvent, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { FunctionComponent, RefObject, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { BiddingMethod, biddingMethodsWith_currencyValues } from '../api/campaign-mapping-contracts';
import { campaignMappingService, invalidateProfile_campaignMappingQueryKeys } from '../api/campaign-mapping-service';
import useCampaignToAdGroupsMappingData from '../hooks/useCampaignToAdGroupsMappingData';
import { CampaignMappingModel } from '../models/CampaignMappingModel';

export enum CampaignMappingUpdateType {
  NO_CHANGE = 'NO_CHANGE',
  SET_BID_METHOD_AND_VALUE = 'SET_BID_METHOD_AND_VALUE',
  SET_BID_FLOOR = 'SET_BID_FLOOR',
  SET_BID_CEILING = 'SET_BID_CEILING',
  SET_NEGATIVES = 'SET_NEGATIVES',
}

const TYPES_TO_USE_MATCH_COUNT = [
  CampaignMappingUpdateType.NO_CHANGE,
  CampaignMappingUpdateType.SET_BID_METHOD_AND_VALUE,
  CampaignMappingUpdateType.SET_BID_FLOOR,
  CampaignMappingUpdateType.SET_BID_CEILING,
];

export interface CampaignMappingUpdateData {
  campaignMappingUpdateType: CampaignMappingUpdateType;
  newValue: number | null;
  newValueDisplay: string | null; // used for displaying the new value in the UI which also allows intermediary value like "2." when entering "2.2"
  newBidMethod: BiddingMethod; // method and value always set together
}

interface CampaignMappingBulkEditPopoverProps {
  buttonRef: RefObject<HTMLButtonElement>;
  selectedCampaignMappings: CampaignMappingModel[];
  selectedMatchesCount: number;
  isOpen: boolean;
  onClose: () => void;
}

const CampaignMappingBulkEditPopover: FunctionComponent<CampaignMappingBulkEditPopoverProps> = ({
  selectedCampaignMappings,
  isOpen,
  onClose,
  buttonRef,
  selectedMatchesCount,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { getCurrencyInputProps } = useHelperComponents();
  const { activeProfile } = useActiveTeamContext();
  const { campaignToAdGroupsMap } = useCampaignToAdGroupsMappingData();
  const { toastWarnWithSetMessages } = useHelperComponents();
  const { getNewBidValue_byCurrentProfileMarketplaceLimits } = useBidLimits();

  const [isLoading, setIsLoading] = useState(false);

  const [campaignMappingUpdateData, setCampaignMappingUpdateData] = useState<CampaignMappingUpdateData>({
    campaignMappingUpdateType: CampaignMappingUpdateType.NO_CHANGE,
    newValue: null,
    newValueDisplay: null,
    newBidMethod: BiddingMethod.ADLABS,
  });
  const [selectedNegatives, setSelectedNegatives] = useState<NegativeMatchType[]>([]);

  // HANDLE CHANGE
  const handleCampaignMappingUpdateTypeChange = (event: SelectChangeEvent<CampaignMappingUpdateType>) => {
    setCampaignMappingUpdateData({
      ...campaignMappingUpdateData,
      campaignMappingUpdateType: event.target.value as CampaignMappingUpdateType,
      newValue: null,
      newValueDisplay: null,
    });
  };

  const handleBiddingMethodChange = (event: SelectChangeEvent<BiddingMethod>) => {
    setCampaignMappingUpdateData({
      ...campaignMappingUpdateData,
      newBidMethod: event.target.value as BiddingMethod,
      newValue: null,
      newValueDisplay: null,
    });
  };

  const onNewSettingValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (isValidPositiveNumberOrEmptyString(value)) {
      // Do not parseFloat here, otherwise can't enter "2." when trying to input "2.2"
      const newValue = value === '' || value === '.' ? null : parseFloat(value);
      setCampaignMappingUpdateData({ ...campaignMappingUpdateData, newValue, newValueDisplay: value });
    }
  };

  const UPDATE_OPTIONS = [
    { value: CampaignMappingUpdateType.NO_CHANGE, label: 'No change' },
    { value: CampaignMappingUpdateType.SET_BID_METHOD_AND_VALUE, label: 'Set bid method and value' },
    { value: CampaignMappingUpdateType.SET_BID_FLOOR, label: 'Set bid floor' },
    { value: CampaignMappingUpdateType.SET_BID_CEILING, label: 'Set bid ceiling' },
    { value: CampaignMappingUpdateType.SET_NEGATIVES, label: 'Set negatives' },
  ];

  const BIDDING_METHOD_OPTIONS = [
    { value: BiddingMethod.ADLABS, label: 'AdLabs' },
    { value: BiddingMethod.CPC_PLUS, label: t(`search_terms_page.bidding_method.${BiddingMethod.CPC_PLUS}`) },
    { value: BiddingMethod.CPC_MINUS, label: t(`search_terms_page.bidding_method.${BiddingMethod.CPC_MINUS}`) },
    { value: BiddingMethod.CPC_TIMES, label: t(`search_terms_page.bidding_method.${BiddingMethod.CPC_TIMES}`) },
    { value: BiddingMethod.CUSTOM, label: t(`search_terms_page.bidding_method.${BiddingMethod.CUSTOM}`) },
  ];

  // APPLY CHANGES
  const handleApplyChanges = async () => {
    setIsLoading(true);
    const warnings = new Set<string>();

    const mappingUpdates = createCampaignMappingUpdates(selectedCampaignMappings, campaignMappingUpdateData, selectedNegatives, warnings);

    if (warnings.size > 0) {
      toastWarnWithSetMessages(warnings);
    }

    if (mappingUpdates.length === 0) {
      toast.info('Nothing to change');
      setIsLoading(false);
      return;
    }

    try {
      const response = await campaignMappingService.updateCampaignMappingParams(mappingUpdates);

      if (!response.isSuccess) {
        toast.error(response.message);
      } else {
        invalidateProfile_campaignMappingQueryKeys(queryClient, activeProfile?.id);

        toast.success(`Campaign mappings successfully edited`);

        onClose();
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to create campaign mapping: ' + error);
    } finally {
      setIsLoading(false);
    }
  };

  function getValidBidValueAndSetWarnings(
    newValue: number | null,
    campaignMapping: CampaignMappingModel,
    warnings: Set<string>,
  ): number | null {
    if (newValue === null) return null;

    const clampingWarnings = new Set<string>();
    let valueToSet: number | null = null;
    let clampedValue: number | null = null;
    const destinationCampaign = campaignToAdGroupsMap?.[campaignMapping.destinationCampaignId ?? ''];
    if (destinationCampaign && Object.values(CampaignAdType).includes(campaignMapping.destinationCampaignAdType)) {
      clampedValue = getNewBidValue_byCurrentProfileMarketplaceLimits(
        newValue,
        campaignMapping.destinationCampaignAdType,
        destinationCampaign.isVideo,
        clampingWarnings,
      );
    }

    if (clampingWarnings.size > 0 && clampedValue) {
      warnings = new Set([...warnings, ...clampingWarnings]);
      toastWarnWithSetMessages(clampingWarnings);
      valueToSet = clampedValue;
    } else {
      valueToSet = campaignMappingUpdateData.newValue;
    }

    return valueToSet;
  }

  function createCampaignMappingUpdates(
    selectedCampaignMappings: CampaignMappingModel[],
    campaignMappingUpdateData: CampaignMappingUpdateData,
    selectedNegatives: NegativeMatchType[],
    warnings: Set<string>,
  ): CampaignMappingModel[] {
    const BID_UPDATE_TYPES = [
      CampaignMappingUpdateType.SET_BID_FLOOR,
      CampaignMappingUpdateType.SET_BID_CEILING,
      CampaignMappingUpdateType.SET_BID_METHOD_AND_VALUE,
    ];
    if (BID_UPDATE_TYPES.includes(campaignMappingUpdateData.campaignMappingUpdateType)) {
      return selectedCampaignMappings.map((campaignMapping) => {
        const selectedMatchTypes = campaignMapping.selectedMatchTypes;

        const innerDTOs = campaignMapping.dto.mi
          .filter((innerDTO) => selectedMatchTypes.includes(innerDTO.m))
          .map((innerDTO) => {
            if (campaignMappingUpdateData.campaignMappingUpdateType == CampaignMappingUpdateType.SET_BID_FLOOR) {
              innerDTO.bf = getValidBidValueAndSetWarnings(campaignMappingUpdateData.newValue, campaignMapping, warnings);
            } else if (campaignMappingUpdateData.campaignMappingUpdateType == CampaignMappingUpdateType.SET_BID_CEILING) {
              innerDTO.bc = getValidBidValueAndSetWarnings(campaignMappingUpdateData.newValue, campaignMapping, warnings);
            } else if (campaignMappingUpdateData.campaignMappingUpdateType == CampaignMappingUpdateType.SET_BID_METHOD_AND_VALUE) {
              innerDTO.bdm = campaignMappingUpdateData.newBidMethod;
              if (campaignMappingUpdateData.newBidMethod == BiddingMethod.ADLABS) {
                innerDTO.bdmv = null;
              } else {
                innerDTO.bdmv = getValidBidValueAndSetWarnings(campaignMappingUpdateData.newValue, campaignMapping, warnings);
              }
            }
            // else should happen
            return innerDTO;
          });

        const dto = { ...campaignMapping.dto, mi: innerDTOs };

        return new CampaignMappingModel(dto);
      });
    }

    if (campaignMappingUpdateData.campaignMappingUpdateType == CampaignMappingUpdateType.SET_NEGATIVES) {
      const updatedCampaignMappings: CampaignMappingModel[] = [];
      for (const campaignMapping of selectedCampaignMappings) {
        let negativesToAdd = selectedNegatives.filter((n) => {
          if (campaignMapping.destinationAdGroupEntityType === TargetEntityType.KEYWORD) {
            return KEYWORD_NEGATIVES.includes(n);
          } else if (campaignMapping.destinationAdGroupEntityType === TargetEntityType.PRODUCT_TARGET) {
            return PRODUCT_TARGET_NEGATIVES.includes(n);
          }

          return false;
        });

        // AD GROUP NEGATIVES
        if (negativesToAdd.some((n) => AD_GROUP_NEGATIVES.includes(n))) {
          const createNegativeAdGroupsWarning = campaignMapping.createNegativeAdGroupsWarning(campaignToAdGroupsMap);
          if (createNegativeAdGroupsWarning) {
            negativesToAdd = negativesToAdd.filter((n) => !AD_GROUP_NEGATIVES.includes(n));
            warnings.add(createNegativeAdGroupsWarning);
          }
        }

        // CAMPAIGN NEGATIVES
        if (negativesToAdd.some((n) => CAMPAIGN_NEGATIVES.includes(n))) {
          const createNegativeCampaignWarning = campaignMapping.createNegativeCampaignWarning(campaignToAdGroupsMap);
          if (createNegativeCampaignWarning) {
            negativesToAdd = negativesToAdd.filter((n) => !CAMPAIGN_NEGATIVES.includes(n));
            warnings.add(createNegativeCampaignWarning);
          }
        }
        if (negativesToAdd.length == 0) {
          continue;
        }

        const dto = { ...campaignMapping.dto, ...negativesArrayToNegativeBoolsObj(negativesToAdd) };
        updatedCampaignMappings.push(new CampaignMappingModel(dto));
      }

      return updatedCampaignMappings;
    }

    // NO_CHANGE
    return [];
  }
  const numMappings = selectedCampaignMappings.length;

  // NEGATIVES
  const NEGATIVES_OPTIONS = useMemo(
    () => [
      new AlMultiSelectOptionModel('Ad Group Negative Exact', NegativeMatchType.AD_GROUP_NEGATIVE_EXACT),
      new AlMultiSelectOptionModel('Ad Group Negative Phrase', NegativeMatchType.AD_GROUP_NEGATIVE_PHRASE),
      new AlMultiSelectOptionModel('Ad Group Negative Product Target', NegativeMatchType.AD_GROUP_NEGATIVE_PRODUCT_TARGET),
      new AlMultiSelectOptionModel('Campaign Negative Exact', NegativeMatchType.CAMPAIGN_NEGATIVE_EXACT),
      new AlMultiSelectOptionModel('Campaign Negative Phrase', NegativeMatchType.CAMPAIGN_NEGATIVE_PHRASE),
      new AlMultiSelectOptionModel('Campaign Negative Product Target', NegativeMatchType.CAMPAIGN_NEGATIVE_PRODUCT_TARGET),
    ],
    [],
  );

  const countToUse = TYPES_TO_USE_MATCH_COUNT.includes(campaignMappingUpdateData.campaignMappingUpdateType)
    ? selectedMatchesCount
    : numMappings;

  const isApplyDisabled = useMemo(() => {
    const { campaignMappingUpdateType, newValue, newBidMethod } = campaignMappingUpdateData;

    if (campaignMappingUpdateType === CampaignMappingUpdateType.NO_CHANGE) {
      return true;
    }

    // Allow floor and ceiling to be null, so there's a way to remove them

    if (campaignMappingUpdateType === CampaignMappingUpdateType.SET_BID_METHOD_AND_VALUE) {
      if (newBidMethod != BiddingMethod.ADLABS && isNil(newValue)) {
        return true;
      }
    }

    if (campaignMappingUpdateType === CampaignMappingUpdateType.SET_NEGATIVES && (!selectedNegatives || selectedNegatives.length === 0)) {
      return true;
    }

    return false;
  }, [campaignMappingUpdateData, selectedNegatives]);
  return (
    <Popover
      id={'campaign-mapping-bulk-edit-popover'}
      open={isOpen}
      anchorEl={buttonRef.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      slotProps={{ paper: { style: { width: 500 } } }}
      aria-labelledby="edit-selection-popover-title"
    >
      <DialogTitle id="edit-selection-popover-title">Edit selected items</DialogTitle>

      <DialogContent className="min-w-[500px]">
        <div className="flex max-w-96 ">
          You have selected {countToUse} item{countToUse > 1 ? 's' : ''} to edit. Please select the type of edits you would like to apply to
          these items.
        </div>
        <div className="flex flex-col gap-y-6 mt-8 mb-4 min-w-100 ">
          {numMappings > 0 && (
            <div>
              <div className="mb-2 flex items-center font-medium text-sm leading-none pb-0 ">
                Edit {countToUse} item{countToUse > 1 ? 's' : ''}
              </div>

              <div className="flex gap-x-4">
                <AlSelect
                  label={'Update action'}
                  value={campaignMappingUpdateData.campaignMappingUpdateType}
                  options={UPDATE_OPTIONS}
                  onChange={handleCampaignMappingUpdateTypeChange}
                  renderOption={(item) => item.label}
                  valueExtractor={(item) => item.value}
                />

                {campaignMappingUpdateData.campaignMappingUpdateType === CampaignMappingUpdateType.SET_BID_FLOOR && (
                  <TextField
                    fullWidth
                    label={'New Bid Floor'}
                    value={campaignMappingUpdateData.newValueDisplay ?? ''}
                    onChange={onNewSettingValueChange}
                    inputProps={{ pattern: '[0-9]*' }}
                    InputProps={getCurrencyInputProps()}
                    placeholder="Not Set"
                  />
                )}

                {campaignMappingUpdateData.campaignMappingUpdateType === CampaignMappingUpdateType.SET_BID_CEILING && (
                  <TextField
                    fullWidth
                    label={'New Bid Ceiling'}
                    value={campaignMappingUpdateData.newValueDisplay ?? ''}
                    onChange={onNewSettingValueChange}
                    inputProps={{ pattern: '[0-9]*' }}
                    InputProps={getCurrencyInputProps()}
                    placeholder="Not Set"
                  />
                )}

                {campaignMappingUpdateData.campaignMappingUpdateType === CampaignMappingUpdateType.SET_BID_METHOD_AND_VALUE && (
                  <>
                    <AlSelect
                      label={'Bid Method'}
                      value={campaignMappingUpdateData.newBidMethod}
                      options={BIDDING_METHOD_OPTIONS}
                      onChange={handleBiddingMethodChange}
                      renderOption={(item) => item.label}
                      valueExtractor={(item) => item.value}
                    />

                    {campaignMappingUpdateData.newBidMethod !== BiddingMethod.ADLABS && (
                      <TextField
                        fullWidth
                        label={'Value (X)'}
                        value={campaignMappingUpdateData.newValueDisplay ?? ''}
                        placeholder="Not Set"
                        onChange={onNewSettingValueChange}
                        inputProps={{ pattern: '[0-9]*' }}
                        InputProps={
                          biddingMethodsWith_currencyValues.includes(campaignMappingUpdateData.newBidMethod)
                            ? getCurrencyInputProps()
                            : undefined
                        }
                      />
                    )}
                  </>
                )}

                {campaignMappingUpdateData.campaignMappingUpdateType === CampaignMappingUpdateType.SET_NEGATIVES && (
                  <AlMultiSelect<NegativeMatchType>
                    options={NEGATIVES_OPTIONS}
                    selectedOptionIds={selectedNegatives}
                    setSelectedOptionIds={setSelectedNegatives}
                    label="Negatives"
                    placeholderText="Select Negatives"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <LoadingButton onClick={handleApplyChanges} variant="contained" loading={isLoading} disabled={isApplyDisabled}>
          Apply Changes
        </LoadingButton>
      </DialogActions>
    </Popover>
  );
};

export default CampaignMappingBulkEditPopover;
