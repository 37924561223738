import { AlFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import useMetricColumnAggregates from '@/components/grid/hooks/useMetricColumnAggregates';
import { ExpandedGridContext } from '@/components/grid/types';
import { metricsService } from '@/components/metrics/api/metrics-service';
import { MetricAggregates, createOptimizerMetricsQueryKey } from '@/components/metrics/models/MetricsModel';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { CampaignGroupModel } from '../api/campaign/models/CampaignGroupModel';

interface UseCampaignGroupMetricColumnAggregatesProps {
  gridApiRef: React.MutableRefObject<GridApi<CampaignGroupModel> | undefined>;
  gridContextRef: React.MutableRefObject<ExpandedGridContext | undefined>;
  filters: AlFilterModel[];
}

const useCampaignGroupMetricColumnAggregates = ({ gridApiRef, gridContextRef, filters }: UseCampaignGroupMetricColumnAggregatesProps) => {
  const { activeTeam, activeProfile } = useActiveTeamContext();

  const {
    data: metricColumnAggregates,
    isLoading: isMetricsLoading,
    error: metricsError,
  } = useQuery({
    queryKey: createOptimizerMetricsQueryKey(
      activeProfile?.id,
      filters.filter((f) => f.key == FilterKey.DATE || f.key == FilterKey.COMPARE_DATE),
    ),
    queryFn: async () => {
      const result = await metricsService.getCampaignMetrics(
        filters.filter((f) => f.key == FilterKey.DATE || f.key == FilterKey.COMPARE_DATE),
      );
      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading metric values');
      }
    },
    select: (metricValues): MetricAggregates => {
      return isNil(metricValues)
        ? ({} as MetricAggregates)
        : metricValues.reduce((aggregates, metric) => {
            aggregates[metric.key] = { current: metric.current };
            return aggregates;
          }, {} as MetricAggregates);
    },
    enabled: !isEmpty(filters) && !isEmpty(activeProfile?.id) && !isNil(activeTeam) && activeTeam.profiles.length > 0,
  });

  const { onGridReadyForMetricColumnAggregates } = useMetricColumnAggregates({
    gridApiRef,
    gridContextRef,
    metricColumnAggregates,
  });

  return { metricColumnAggregates, isMetricsLoading, metricsError, onGridReadyForMetricColumnAggregates };
};

export default useCampaignGroupMetricColumnAggregates;
