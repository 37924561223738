import useFormatting from '@/hooks/useFormatting';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Button, Checkbox, Divider, Tooltip } from '@mui/material';
import { GridApi } from 'ag-grid-enterprise';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import OppositeModeOverlayBar from '../../../components/OppositeModeOverlayBar';
import { CampaignMappingModel } from '../models/CampaignMappingModel';
import CampaignMappingBulkEditPopover from './CampaignMappingBulkEditPopover';

interface CampaignMappingActionsBarProps {
  selectedCampaignMappings: CampaignMappingModel[];
  setSelectedCampaignMappings: (selectedCampaignMappings: CampaignMappingModel[]) => void;
  totalMappingsCount: number;
  onDiscardClicked: () => void;
  mappingsTableGridApiRef: React.MutableRefObject<GridApi | null>;
}

const CampaignMappingSelectionActionsBar: FunctionComponent<CampaignMappingActionsBarProps> = ({
  selectedCampaignMappings,
  setSelectedCampaignMappings,
  totalMappingsCount,
  onDiscardClicked,
  mappingsTableGridApiRef,
}) => {
  const { activeTeam } = useActiveTeamContext();
  const { formatWithThousandsSeparator } = useFormatting();

  // HANDLE SELECTION
  function onDeselectAll() {
    setSelectedCampaignMappings([]);
    if (mappingsTableGridApiRef.current) {
      mappingsTableGridApiRef.current.deselectAll();
    }
  }

  function onSelectAll() {
    if (mappingsTableGridApiRef.current) {
      mappingsTableGridApiRef.current.selectAll();
    }
  }

  const selectedMatchesCount = useMemo(() => {
    return selectedCampaignMappings.reduce((total, mapping) => {
      return total + (mapping.selectedMatchTypes ? mapping.selectedMatchTypes.length : 0);
    }, 0);
  }, [selectedCampaignMappings]);

  // Bulk Edit
  const bulkEditButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isBulkEditPopoverOpen, setIsBulkEditPopoverOpen] = useState(false);
  const handleBulkEditPopoverClose = () => setIsBulkEditPopoverOpen(false);

  return (
    <>
      <OppositeModeOverlayBar visible={selectedCampaignMappings.length > 0}>
        <div className="flex flex-row gap-4 items-center px-1.5">
          <Tooltip title="Deselect all">
            <div onClick={onDiscardClicked} className="flex flex-row text-sm font-bold items-center hover:cursor-pointer">
              <Checkbox checked={true} name="deselectCheckbox" color="secondary" />
              {formatWithThousandsSeparator(selectedMatchesCount)} selected
            </div>
          </Tooltip>

          <Divider className="my-2" orientation="vertical" flexItem />

          <div className="flex flex-row gap-4 whitespace-nowrap items-center">
            <Button
              ref={bulkEditButtonRef}
              size="small"
              className="whitespace-nowrap"
              onClick={() => setIsBulkEditPopoverOpen(true)}
              variant={isBulkEditPopoverOpen ? 'contained' : 'outlined'}
              startIcon={<AssignmentOutlinedIcon />}
            >
              Bulk Actions
            </Button>

            <Divider className="my-2" orientation="vertical" flexItem />
            <Button
              className="whitespace-nowrap"
              onClick={selectedCampaignMappings.length == totalMappingsCount ? onDeselectAll : onSelectAll}
              variant="text"
            >
              {selectedCampaignMappings.length == totalMappingsCount ? 'Deselect All' : 'Select All'}
            </Button>
          </div>
        </div>
      </OppositeModeOverlayBar>

      {isBulkEditPopoverOpen && (
        <CampaignMappingBulkEditPopover
          buttonRef={bulkEditButtonRef}
          isOpen={isBulkEditPopoverOpen}
          onClose={handleBulkEditPopoverClose}
          selectedCampaignMappings={selectedCampaignMappings}
          selectedMatchesCount={selectedMatchesCount}
        />
      )}
    </>
  );
};

export default CampaignMappingSelectionActionsBar;
