import { SelectedEntityForDataGroup } from '@/modules/data-groups/models/DataItem';
import { AdvertisedProductWithMetricsWithPreviousDTO } from '@/modules/products/api/products-contracts';
import { MetricData, MetricDataWithPreviousDays } from '@/modules/targeting/models/TargetsModel';
import { DTO } from '@/types/dto-wrapper';

export class AdvertisedProductModel extends DTO<AdvertisedProductWithMetricsWithPreviousDTO> {
  constructor(data: AdvertisedProductWithMetricsWithPreviousDTO) {
    super(data);
  }

  public get title(): string {
    return this.dto.t;
  }

  public get imageUrl(): string {
    return this.dto.i;
  }

  public get asin(): string {
    return this.dto.a;
  }

  public get parentAsin(): string {
    return this.dto.pa;
  }

  public get sku(): number {
    return this.dto.s;
  }

  public get campaignName(): string {
    return this.dto.cn;
  }

  public get adGroupName(): string {
    return this.dto.an;
  }

  // Metric getters
  public get impressions(): MetricData {
    return this.dto.impressions;
  }

  public get clicks(): MetricData {
    return this.dto.clicks;
  }

  public get orders(): MetricData {
    return this.dto.orders;
  }

  public get ctr(): MetricData {
    return this.dto.ctr;
  }

  public get cvr(): MetricData {
    return this.dto.cvr;
  }

  public get cpc(): MetricData {
    return this.dto.cpc;
  }

  public get spend(): MetricDataWithPreviousDays {
    return this.dto.spend;
  }

  public get sales(): MetricDataWithPreviousDays {
    return this.dto.sales;
  }

  public get acos(): MetricData {
    return this.dto.acos;
  }

  public get roas(): MetricData {
    return this.dto.roas;
  }

  public get rpc(): MetricData {
    return this.dto.rpc;
  }

  public get cpa(): MetricData {
    return this.dto.cpa;
  }

  public get aov(): MetricData {
    return this.dto.aov;
  }

  public get cpm(): MetricData {
    return this.dto.cpm;
  }

  public get advertisedAdvertisedProductDataItemIds(): number[] {
    return this.dto.pd ?? [];
  }

  public static fromResponse(dto: AdvertisedProductWithMetricsWithPreviousDTO): AdvertisedProductModel {
    return new AdvertisedProductModel(dto);
  }

  public static fromResponseArray(dtos: AdvertisedProductWithMetricsWithPreviousDTO[] | null): AdvertisedProductModel[] {
    return dtos ? dtos.map(AdvertisedProductModel.fromResponse) : [];
  }
}

export interface SelectedAdvertisedProduct extends SelectedEntityForDataGroup {
  id: string;
}
