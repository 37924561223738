import { filtersService } from '@/components/filter-builder/api/filters-service';
import { AlFilterModel, getDefaultTargetFilters, getUpdatedFiltersValue } from '@/components/filter-builder/models/AlFilterModel';
import { ComparisonUnit } from '@/components/grid/types';
import { METRIC_COLORS, MetricColor, getUpdatedSelectionColors } from '@/components/metrics/MetricsConfig';
import { MetricField } from '@/components/metrics/models/CommonMetricsModel';
import { useUserSetting } from '@/hooks/useUserSetting';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { UserSettingKey } from '@/modules/users';
import { cloneDeep, isEqual } from 'lodash-es';
import { FunctionComponent, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

export const TARGETING_CONTEXT_KEY = 'targeting-context';

interface TargetingContext {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
  setFilterValue: (filter: AlFilterModel) => void;
  setFilterValues: (filters: AlFilterModel[]) => void;
  visibleMetrics: MetricField[];
  setVisibleMetrics: React.Dispatch<React.SetStateAction<MetricField[]>>;
  selectedMetrics: MetricField[];
  setSelectedMetrics: React.Dispatch<React.SetStateAction<MetricField[]>>;
  selectedMetricColors: MetricColor[];
  setSelectedMetricColors: React.Dispatch<React.SetStateAction<MetricColor[]>>;
  comparisonUnit: ComparisonUnit;
  setComparisonUnit: React.Dispatch<React.SetStateAction<ComparisonUnit>>;
}

export const TargetingContext = createContext<TargetingContext>({
  filters: [],
  setFilters: () => {},
  setFilterValue: () => {},
  setFilterValues: () => {},
  visibleMetrics: [],
  setVisibleMetrics: () => {},
  selectedMetrics: [],
  setSelectedMetrics: () => {},
  selectedMetricColors: [],
  setSelectedMetricColors: () => {},
  comparisonUnit: 'nominal',
  setComparisonUnit: () => {},
});

const DEFAULT_SELECTED_METRICS: MetricField[] = [MetricField.ACOS, MetricField.SALES];
const DEFAULT_SELECTED_METRIC_COLORS: MetricColor[] = [
  { key: MetricField.ACOS, color: METRIC_COLORS[0] },
  { key: MetricField.SALES, color: METRIC_COLORS[1] },
];
const DEFAULT_VISIBLE_METRICS: MetricField[] = [
  MetricField.IMPRESSIONS,
  MetricField.CLICKS,
  MetricField.ORDERS,
  MetricField.SPEND,
  MetricField.SALES,
  MetricField.ACOS,
  MetricField.CTR,
  MetricField.CVR,
];

// TODO: use a shared props interface for all these contexts
interface TargetingProviderProps extends PropsWithChildren {
  filters: AlFilterModel[];
  setFilters: React.Dispatch<React.SetStateAction<AlFilterModel[]>>;
}

export const TargetingProvider: FunctionComponent<TargetingProviderProps> = ({ filters, setFilters, children }) => {
  const { activeProfileIdChange, activeProfile } = useActiveTeamContext();
  const { handleSettingStateChange: updateSelectedMetricsSetting, settingState: selectedMetricsSetting } = useUserSetting<MetricField[]>(
    UserSettingKey.SELECTED_METRICS,
  );

  const { handleSettingStateChange: updateSelectedMetricColorsSetting, settingState: selectedMetricColorsSetting } = useUserSetting<
    MetricColor[]
  >(UserSettingKey.SELECTED_METRIC_COLORS);

  const { handleSettingStateChange: updateVisibleMetrics, settingState: visibleMetricsSetting } = useUserSetting<MetricField[]>(
    UserSettingKey.VISIBLE_METRICS,
  );

  const [selectedMetrics, setSelectedMetrics] = useState<MetricField[]>(selectedMetricsSetting ?? DEFAULT_SELECTED_METRICS);
  const [visibleMetrics, setVisibleMetrics] = useState<MetricField[]>(visibleMetricsSetting ?? DEFAULT_VISIBLE_METRICS);
  const [selectedMetricColors, setSelectedMetricColors] = useState<MetricColor[]>(
    getUpdatedSelectionColors(
      selectedMetricColorsSetting ?? DEFAULT_SELECTED_METRIC_COLORS,
      selectedMetricsSetting ?? DEFAULT_SELECTED_METRICS,
    ),
  );

  const [comparisonUnit, setComparisonUnit] = useState<ComparisonUnit>('percent');
  const [isMounted, setIsMounted] = useState(false);

  const setFilterValue = (filter: AlFilterModel) => {
    const newFilters = getUpdatedFiltersValue(filters, filter);
    setFilterValues(newFilters);
  };

  const setFilterValues = (newFilters: AlFilterModel[]) => {
    if (!newFilters || newFilters.length === 0) return;

    const updatedFilters = newFilters.reduce((acc, newFilter) => {
      return getUpdatedFiltersValue(acc, newFilter);
    }, cloneDeep(filters));

    if (isEqual(filters, updatedFilters)) return;

    setFilters(updatedFilters);
  };

  useEffect(() => {
    if (!isMounted) return;
    updateSelectedMetricColorsSetting(selectedMetricColors);
  }, [selectedMetricColors]);

  useEffect(() => {
    if (!isMounted) return;
    updateSelectedMetricsSetting(selectedMetrics);
  }, [selectedMetrics]);

  useEffect(() => {
    if (!isMounted) return;
    updateVisibleMetrics(visibleMetrics);
  }, [visibleMetrics]);

  //TODO: review
  useEffect(() => {
    if (!isMounted) return;
    if (!activeProfile) return;

    filtersService.saveProfileFilters(TARGETING_CONTEXT_KEY, activeProfile.id, filters);
  }, [filters]);

  useEffect(() => {
    if (activeProfileIdChange && activeProfileIdChange.hasChanged && activeProfile?.id) {
      const profileFilters = filtersService.loadProfileFilters(TARGETING_CONTEXT_KEY, activeProfile?.id, getDefaultTargetFilters());

      if (!isEqual(profileFilters, filters)) {
        setFilters(profileFilters);
      }
    }
  }, [activeProfileIdChange]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <TargetingContext.Provider
      value={{
        filters,
        setFilters,
        setFilterValue,
        setFilterValues,
        visibleMetrics,
        setVisibleMetrics,
        selectedMetrics,
        setSelectedMetrics,
        selectedMetricColors,
        setSelectedMetricColors,
        comparisonUnit,
        setComparisonUnit,
      }}
    >
      {children}
    </TargetingContext.Provider>
  );
};

export const useTargetingContext = (): TargetingContext => {
  const context = useContext(TargetingContext);
  if (!context) {
    throw new Error('useTargetingContext must be used within a TargetingProvider');
  }
  return context;
};
