import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { MetricField } from '../metrics/models/CommonMetricsModel';

// weekStart: 0 (Sunday) to 6 (Saturday)
function getWeekNumber(dateString: string, weekStart: number = 0): number {
  // Convert the date string to a Date object
  let date;
  try {
    date = new Date(dateString);
  } catch (error) {
    console.log(error);
    return 1;
  }

  // Make sure weekStart is within the valid range
  weekStart = ((weekStart % 7) + 7) % 7;

  // Correct the day number according to the week start day
  const dayNum = (date.getDay() + 7 - weekStart) % 7;

  // Create a copy of the date so the original date won't be modified
  const tempDate = new Date(date.valueOf());

  // Set the target to the nearest Thursday (current date + 4 - current day number)
  tempDate.setDate(tempDate.getDate() - dayNum + 3);

  // ISO 8601 week number of the year for this date
  const firstThursday = tempDate.valueOf();

  // Set the target to the first day of the year
  tempDate.setMonth(0, 1);

  // If this is not a Thursday, set the target to the next Thursday
  if (tempDate.getDay() !== 4) {
    tempDate.setMonth(0, 1 + ((4 - tempDate.getDay() + 7) % 7));
  }

  // The weeknumber is the number of weeks between the first Thursday of the year
  // and the Thursday in the target week
  return 1 + Math.ceil((firstThursday - tempDate.valueOf()) / 604800000); // 604800000 = number of milliseconds in a week
}

export function aggregateWeekly(data: TimelineModel): TimelineModel {
  const initialWeeklyData = new Map<string, { [key in MetricField]?: number }>();
  const weekDayCount = new Map<string, number>();

  // Initialize initialWeeklyData and count days per week
  data.xAxisData.forEach((date) => {
    const weekNumber = getWeekNumber(date);
    const weekKey = weekNumber.toString();

    if (!initialWeeklyData.has(weekKey)) {
      initialWeeklyData.set(weekKey, {});
      weekDayCount.set(weekKey, 0);
    }

    weekDayCount.set(weekKey, (weekDayCount.get(weekKey) || 0) + 1);
  });

  // Sum up direct metrics weekly
  data.yAxisData.forEach((metric) => {
    metric.values.forEach((value, index) => {
      const weekNumber = getWeekNumber(data.xAxisData[index]);
      const weeklyMetrics = initialWeeklyData.get(weekNumber.toString());
      if (weeklyMetrics) {
        const metricKey = metric.key;
        weeklyMetrics[metricKey] = (weeklyMetrics[metricKey] || 0) + value;
      }
    });
  });

  // Check if the week is partial
  const isWeekPartial = (weekNumber: string): boolean => {
    const dayCount = weekDayCount.get(weekNumber);
    return dayCount !== undefined && dayCount < 7;
  };

  // Create a new weeklyData with updated keys (add Partial where needed)
  const weeklyData = new Map<string, { [key in MetricField]?: number }>();
  initialWeeklyData.forEach((metrics, weekNumber) => {
    const newKey = `Week ${weekNumber}${isWeekPartial(weekNumber) ? ' (Partial)' : ''}`;
    weeklyData.set(newKey, metrics);
  });

  // TODO: refactor so that opt table, row grouping and this all use the same formulas for calculated metrics
  weeklyData.forEach((weeklyMetrics) => {
    // Calculating Click-Through Rate (CTR)
    if (weeklyMetrics[MetricField.CLICKS] !== undefined && weeklyMetrics[MetricField.IMPRESSIONS] !== undefined) {
      weeklyMetrics[MetricField.CTR] = weeklyMetrics[MetricField.CLICKS] / weeklyMetrics[MetricField.IMPRESSIONS];
    }

    // Calculating Advertising Cost of Sales (ACOS)
    if (weeklyMetrics[MetricField.SPEND] !== undefined && weeklyMetrics[MetricField.SALES]) {
      weeklyMetrics[MetricField.ACOS] = weeklyMetrics[MetricField.SPEND] / weeklyMetrics[MetricField.SALES];
    }

    // Calculating Return on Advertising Spend (ROAS)
    if (weeklyMetrics[MetricField.SALES] !== undefined && weeklyMetrics[MetricField.SPEND]) {
      weeklyMetrics[MetricField.ROAS] = weeklyMetrics[MetricField.SALES] / weeklyMetrics[MetricField.SPEND];
    }

    // Calculating Revenue Per Click (RPC)
    if (weeklyMetrics[MetricField.SALES] !== undefined && weeklyMetrics[MetricField.CLICKS]) {
      weeklyMetrics[MetricField.RPC] = weeklyMetrics[MetricField.SALES] / weeklyMetrics[MetricField.CLICKS];
    }

    // Calculating Cost Per Acquisition (CPA)
    if (weeklyMetrics[MetricField.SPEND] !== undefined && weeklyMetrics[MetricField.ORDERS]) {
      weeklyMetrics[MetricField.CPA] = weeklyMetrics[MetricField.SPEND] / weeklyMetrics[MetricField.ORDERS];
    }

    // Calculating Average Order Value (AOV)
    if (weeklyMetrics[MetricField.SALES] !== undefined && weeklyMetrics[MetricField.ORDERS]) {
      weeklyMetrics[MetricField.AOV] = weeklyMetrics[MetricField.SALES] / weeklyMetrics[MetricField.ORDERS];
    }

    // Calculating Cost Per Click (CPC)
    if (weeklyMetrics[MetricField.SPEND] !== undefined && weeklyMetrics[MetricField.CLICKS]) {
      weeklyMetrics[MetricField.CPC] = weeklyMetrics[MetricField.SPEND] / weeklyMetrics[MetricField.CLICKS];
    }

    // Calculating Cost Per Mille (CPM)
    if (weeklyMetrics[MetricField.SPEND] !== undefined && weeklyMetrics[MetricField.IMPRESSIONS]) {
      weeklyMetrics[MetricField.CPM] = (weeklyMetrics[MetricField.SPEND] / weeklyMetrics[MetricField.IMPRESSIONS]) * 1000;
    }

    // Calculating Conversion Rate (CVR)
    if (weeklyMetrics[MetricField.ORDERS] !== undefined && weeklyMetrics[MetricField.CLICKS]) {
      weeklyMetrics[MetricField.CVR] = weeklyMetrics[MetricField.ORDERS] / weeklyMetrics[MetricField.CLICKS];
    }
  });

  return fromWeeklyData(weeklyData);
}

function fromWeeklyData(weeklyData: Map<string, { [key in MetricField]?: number }>): TimelineModel {
  const xAxisData: string[] = [];
  const yAxisData: {
    key: MetricField;
    values: number[];
  }[] = [];

  // Assuming weeklyData is not empty, get the first entry's metrics
  // Use this instead Object.keys(MetricField) to maintain metric order on graph
  const firstWeekMetrics = weeklyData.size > 0 ? Array.from(weeklyData.values())[0] : {};

  // Initialize yAxisData with empty arrays for each metric in the order they appear in weeklyData
  Object.keys(firstWeekMetrics).forEach((metricKey) => {
    yAxisData.push({
      key: metricKey as MetricField,
      values: [],
    });
  });

  // Populate xAxisData and accumulate weekly values in yAxisData
  weeklyData.forEach((metrics, weekNumber) => {
    xAxisData.push(`${weekNumber}`);

    yAxisData.forEach((metricData) => {
      const metricValue = metrics[metricData.key as keyof typeof metrics] ?? 0;
      metricData.values.push(metricValue);
    });
  });

  return new TimelineModel({
    xAxisData: xAxisData,
    yAxisData: yAxisData,
  });
}
