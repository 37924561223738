import AlErrorBoundary from '@/components/feedback/AlErrorBoundary';
import ProfileDataStatusAlerts from '@/components/feedback/ProfileDataStatusAlerts';
import { filtersService } from '@/components/filter-builder/api/filters-service';
import { useTranslation } from '@/lib';
import { PageLayoutBody, PageLayoutTopBar } from '@/modules/application';
import { PageLayout } from '@/modules/application/layouts/PageLayout';
import ProfileSyncSelectButton from '@/modules/optimizer/ProfileSyncSelectButton';
import { TeamSelect } from '@/modules/teams/components/TeamSelect';
import UpgradeSubscriptionButton from '@/modules/teams/components/UpgradeSubscriptionButton';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { useQuery } from '@tanstack/react-query';
import { GridReadyEvent } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { isEmpty, isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AlFilterModel, getDefaultNegativeTargetingFilters } from '../../../components/filter-builder/models/AlFilterModel';
import { createNegativeTargetingQueryKey, negativeTargetingService } from '../api/negative-targets-service';
import NegativeTargetingActions from '../components/NegativeTargetingActions';
import NegativeTargetingFilterBar from '../components/NegativeTargetingFilterBar';
import NegativeTargetingSelectionActionsBar from '../components/NegativeTargetingSelectionActionsBar';
import NegativeTargetingTable from '../components/negative-targets-table/NegativeTargetingTable';
import { NEGATIVE_TARGETS_CONTEXT_KEY, NegativeTargetingProvider } from '../contexts/NegativeTargetsContext';
import { SelectedNegativeTarget } from '../models/NegativeTargetsModel';
import { usePaywall } from '@/modules/plans/hooks/usePaywall';

let isPaywallDisplayedBefore = false;

const NegativeTargetingPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { activeTeam, activeProfile, hasUserOverMaxAllowedFreeProfiles } = useActiveTeamContext();
  const { paywallModalElement, showPaywallWithDelay } = usePaywall({ message: 'Upgrade to Pro to Access Negative Keyword Tools' });

  const [filters, setFilters] = useState<AlFilterModel[]>(() => {
    // Setting via function to avoid unnecessary loading on re-render
    return filtersService.loadProfileFilters(NEGATIVE_TARGETS_CONTEXT_KEY, activeProfile?.id ?? '', getDefaultNegativeTargetingFilters());
  });
  const [selectedNegativeTargeting, setNegativeTargeting] = useState<SelectedNegativeTarget[]>([]);
  const [isShowingAlerts, setIsShowingAlerts] = useState<boolean>(true);

  const negativeTargetingTableGridApi = useRef<GridApi | null>(null);
  function clearSelections() {
    setNegativeTargeting([]);
    if (negativeTargetingTableGridApi.current && !negativeTargetingTableGridApi.current.isDestroyed()) {
      negativeTargetingTableGridApi.current.deselectAll();
    }
  }

  const negativeTargetingQueryKey = createNegativeTargetingQueryKey(activeProfile?.id, filters);
  const {
    data: negativeTargetsData,
    isLoading: isNegativeTargetingRowDataLoading,
    isError: isNegativeTargetingLoadingError,
    error: negativeTargetingLoadingError,
  } = useQuery({
    queryKey: negativeTargetingQueryKey,
    queryFn: async () => {
      const result = await negativeTargetingService.getNegativeTargets(filters);
      if (result.isSuccess) {
        clearSelections();
        return result.payload;
      } else {
        toast.error('Error loading negative targets ' + JSON.stringify(result));
      }
    },
    enabled: !isEmpty(filters) && !isEmpty(activeProfile?.id),
  });

  function onNegativeTargetingTableGridReady(params: GridReadyEvent) {
    negativeTargetingTableGridApi.current = params.api;
  }

  useEffect(() => {
    const paywallDelay = isPaywallDisplayedBefore ? 0 : 10;

    if (hasUserOverMaxAllowedFreeProfiles()) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
    }

    if (isNil(activeTeam)) {
      toast.error('Error: Active team is not set');
      return;
    }

    if (!activeTeam.subscriptionPlan.canUseNegativeTargetingPage) {
      showPaywallWithDelay(paywallDelay);
      isPaywallDisplayedBefore = true;
      return;
    }
  }, []);

  // Add negatives modal
  // const [isCreateNegativesModalOpen, setIsCreateNegativesModalOpen] = useState(false);
  return (
    <>
      <PageLayout>
        <PageLayoutTopBar
          header={t('negative_keywords_page.header')}
          actions={
            <div className="flex flex-row items-center gap-2">
              {!activeTeam?.hasProPlan && <UpgradeSubscriptionButton />}
              <TeamSelect />
              <ProfileSyncSelectButton setFilters={setFilters} />
            </div>
          }
        />
        <ProfileDataStatusAlerts isShowingAlerts={isShowingAlerts} setIsShowingAlerts={setIsShowingAlerts} />

        {!isShowingAlerts && (
          <PageLayoutBody suppressBottomPadding>
            <NegativeTargetingProvider filters={filters} setFilters={setFilters}>
              <AlErrorBoundary>
                <NegativeTargetingActions negativeTargetsData={negativeTargetsData} />
              </AlErrorBoundary>
              <AlErrorBoundary>
                <NegativeTargetingFilterBar />
              </AlErrorBoundary>

              <AlErrorBoundary>
                <NegativeTargetingTable
                  rowData={negativeTargetsData?.negativeTargeting ?? []}
                  isLoading={isNegativeTargetingRowDataLoading}
                  setSelectedNegativeTargeting={setNegativeTargeting}
                  negativeTargetingLoadingErrorMessage={
                    negativeTargetingLoadingError instanceof Error ? negativeTargetingLoadingError.message : ''
                  }
                  isNegativeTargetingLoadingError={isNegativeTargetingLoadingError}
                  onGridReadyCallback={onNegativeTargetingTableGridReady}
                  noTopBorderRadius={true}
                />
              </AlErrorBoundary>
            </NegativeTargetingProvider>
          </PageLayoutBody>
        )}
      </PageLayout>
      <AlErrorBoundary>
        <NegativeTargetingSelectionActionsBar
          selectedNegativeTargeting={selectedNegativeTargeting}
          totalNegativeTargetingCount={negativeTargetsData?.negativeTargeting ? negativeTargetsData.negativeTargeting.length : 0}
          onDiscardClicked={clearSelections}
        />
      </AlErrorBoundary>
      {paywallModalElement}
    </>
  );
};

export default NegativeTargetingPage;
