import { ApiResponse } from '@/lib/api/api-response';
import { apiProfileClient } from '@/lib/api/base-client';
import { AlFilterModel } from '../../filter-builder/models/AlFilterModel';
import { MetricsModel } from '../models/MetricsModel';
import { MetricsDTO, MetricsRequest } from './metrics-contracts';

// TODO: refactor
export class MetricService {
  async getCampaignMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricsModel[]>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: MetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<MetricsDTO>('/campaigns/metrics', requestData);

      return applicationResponse.processPayload(MetricsModel.fromDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getTargetMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricsModel[]>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: MetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<MetricsDTO>('/targets/metrics', requestData);

      return applicationResponse.processPayload(MetricsModel.fromDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getPlacementsMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricsModel[]>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: MetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<MetricsDTO>('/campaigns/bid-adjustments-metrics', requestData);

      return applicationResponse.processPayload(MetricsModel.fromDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getSearchTermsMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricsModel[]>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: MetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<MetricsDTO>('/search-terms/metrics', requestData);

      return applicationResponse.processPayload(MetricsModel.fromDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getProductsMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricsModel[]>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: MetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<MetricsDTO>('/products/metrics', requestData);

      return applicationResponse.processPayload(MetricsModel.fromDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAdvertisedProductsMetrics(filters: AlFilterModel[]): Promise<ApiResponse<MetricsModel[]>> {
    try {
      const filterData = filters.map((filter) => filter.toDTO());

      const requestData: MetricsRequest = {
        filters: filterData,
      };

      const applicationResponse = await apiProfileClient.post<MetricsDTO>('/products/advertised/metrics', requestData);

      return applicationResponse.processPayload(MetricsModel.fromDTO);
    } catch (error) {
      console.error(error);
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const metricsService = new MetricService();
