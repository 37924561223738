import { ColumnId } from '@/components/grid/columns/columns.enum';
import { ColumnState } from 'ag-grid-enterprise';

export function generateAdvertisedProductsTableColumnState(): ColumnState[] {
  return [
    //{ colId: ColumnId.CHECKBOX, hide: false, pinned: 'left' },
    //{ colId: ColumnId.ID, hide: true, pinned: 'left' },
    { colId: ColumnId.TITLE, width: 440, pinned: 'left' },
    { colId: ColumnId.ASIN, width: 90 },
    { colId: ColumnId.CAMPAIGN_NAME },
    { colId: ColumnId.AD_GROUP },
    { colId: ColumnId.IMPRESSIONS },
    { colId: ColumnId.CLICKS },
    { colId: ColumnId.ORDERS },
    { colId: ColumnId.CTR },
    { colId: ColumnId.CVR },
    { colId: ColumnId.CPC },
    { colId: ColumnId.SPEND },
    { colId: ColumnId.SALES },
    { colId: ColumnId.ACOS },
    { colId: ColumnId.ROAS },
    { colId: ColumnId.RPC },
    { colId: ColumnId.CPA },
    { colId: ColumnId.AOV },
    { colId: ColumnId.CPM },
  ];
}
