import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Location, useBlocker, NavigationType } from 'react-router-dom';
import { ConfirmationModal } from './ConfirmationModal';

interface UseNavigationConfirmationModalProps {
  questionText?: string | React.JSX.Element;
  questionTitle?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const useNavigationConfirmationModal = (
  {
    questionText = 'Are you sure you want to leave this page?',
    questionTitle = 'Confirm Navigation',
    confirmButtonText = 'Leave',
    cancelButtonText = 'Stay',
  }: UseNavigationConfirmationModalProps = {
    questionText: 'Are you sure you want to leave this page?',
    questionTitle: 'Confirm Navigation',
    confirmButtonText: 'Leave',
    cancelButtonText: 'Stay',
  },
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [isBlocking, setIsBlocking] = useState(false);
  const overrideBlocker = useRef(false);
  const navigate = useNavigate();

  const handleOpenModal = useCallback((location: Location) => {
    setIsModalOpen(true);
    setNextLocation(location);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNextLocation(null);
  };

  const blocker = useCallback(
    (args: { currentLocation: Location<unknown>; nextLocation: Location<unknown>; historyAction: NavigationType }) => {
      if (args.nextLocation && overrideBlocker.current) {
        return false;
      }
      if (
        (isBlocking && args.currentLocation.pathname !== args.nextLocation.pathname) ||
        (isBlocking && args.historyAction === NavigationType.Pop)
      ) {
        handleOpenModal(args.nextLocation);
        return true;
      }
      return false;
    },
    [handleOpenModal, isBlocking],
  );

  const blockerHook = useBlocker(blocker);

  useEffect(() => {
    return () => {
      if (blockerHook) {
        blockerHook.reset && blockerHook.reset();
      }
    };
  }, []);

  const handleConfirmAction = () => {
    setIsModalOpen(false);

    if (nextLocation) {
      setIsBlocking(false);
      setNextLocation(null);

      overrideBlocker.current = true;
      navigate(nextLocation.pathname);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isBlocking) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isBlocking]);

  const ModalComponent = (
    <ConfirmationModal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      onConfirm={handleConfirmAction}
      questionText={questionText}
      questionTitle={questionTitle}
      confirmButtonText={confirmButtonText}
      cancelButtonText={cancelButtonText}
    />
  );

  return { ModalComponent, setBlock: setIsBlocking };
};
