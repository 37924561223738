export enum WizardStepState {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export enum AccountType {
  Agency = 'Agency',
  Brand = 'Brand',
  Freelancer = 'Freelancer',
}

export enum SpendType {
  First = '<$10,000',
  Second = '$10,000 - $20,000',
  Third = '$20,000 - $50,000',
  Fourth = '$50,000 - $150,000',
  Fifth = '$150,000+',
}
