import { CommonMetricsDTO } from '@/components/metrics/api/metrics-contracts';
import { TimelineDTO } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { ProductModel } from '../models/ProductModel';
import { AdvertisedProductModel } from '@/modules/advertised-products/models/AdvertisedProductModel';

export enum ProductAvailability {
  IN_STOCK = 'IN_STOCK', //The item is in stock.
  IN_STOCK_SCARCE = 'IN_STOCK_SCARCE', //The item is in stock, but stock levels are limited.
  OUT_OF_STOCK = 'OUT_OF_STOCK', //The item is currently out of stock.
  PREORDER = 'PREORDER', //The item is not yet available, but can be pre-ordered.
  LEADTIME = 'LEADTIME', //The item is only available after some amount of lead time.
  AVAILABLE_DATE = 'AVAILABLE_DATE', //The item is not available, but will be available on a future date.
  ERROR = 'ERROR', // front only
}

export interface ProductWithMetricsWithPreviousDTO extends CommonMetricsDTO {
  /**
   * Title
   */
  t: string;
  /**
   * Availability
   */
  av: ProductAvailability;
  /**
   * BasisPrice
   */
  bp: number;
  /**
   * PriceToPay
   */
  pp: number;
  /**
   * BestSellerRank
   */
  b: number;
  /**
   * ImageUrl
   */
  i: string;
  /**
   * asin
   */
  a: string;
  /**
   * ParentAsin
   */
  pa: string;
  /**
   * sku
   */
  s: number;
  /**
   * Category
   */
  c: string;
  /**
   * Brand
   */
  bd: string;

  /**
   * Product Data Group item IDs
   */
  pd: number[] | null;
}

export interface ProductsWithTimelineDTO {
  products: ProductWithMetricsWithPreviousDTO[];
  timeline: TimelineDTO;
  comparison_missing: boolean;
}

export interface ProductsWithTimeline {
  products: ProductModel[];
  timeline: TimelineModel;
  isComparisonDataMissing: boolean;
}

export interface AdvertisedProductWithMetricsWithPreviousDTO extends CommonMetricsDTO {
  /**
   * Title
   */
  t: string;
  /**
   * ImageUrl
   */
  i: string;
  /**
   * asin
   */
  a: string;
  /**
   * ParentAsin
   */
  pa: string;
  /**
   * sku
   */
  s: number;
  /**
   * campaign id
   */
  ci: string;
  /**
   * campaign name
   */
  cn: string;
  /**
   * ad group id
   */
  ai: string;
  /**
   * ad group name
   */
  an: string;

  /**
   * Product Data Group item IDs
   */
  pd: number[] | null;
}

export interface AdvertisedProductsWithTimelineDTO {
  products: AdvertisedProductWithMetricsWithPreviousDTO[];
  timeline: TimelineDTO;
  comparison_missing: boolean;
}

export interface AdvertisedProductsWithTimeline {
  products: AdvertisedProductModel[];
  timeline: TimelineModel;
  isComparisonDataMissing: boolean;
}
