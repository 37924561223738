import {
  EMPTY_METRIC_DATA,
  EMPTY_METRIC_DATA_WITH_PREVIOUS_DAYS,
  MetricData,
  MetricDataWithPreviousDays,
} from '@/modules/targeting/models/TargetsModel';
import { CommonMetricsDTO } from '../api/metrics-contracts';

export enum MetricField {
  IMPRESSIONS = 'impressions',
  CLICKS = 'clicks',
  ORDERS = 'orders',
  CTR = 'ctr',
  CVR = 'cvr',
  CPC = 'cpc',
  SPEND = 'spend',
  SALES = 'sales',
  ACOS = 'acos',
  ROAS = 'roas',
  RPC = 'rpc',
  CPA = 'cpa',
  AOV = 'aov',
  CPM = 'cpm',
}

export function isValidMetricField(value: unknown): value is MetricField {
  return Object.values(MetricField).includes(value as MetricField);
}

export class CommonMetrics implements Record<MetricField, MetricData | MetricDataWithPreviousDays> {
  public impressions: MetricData;
  public clicks: MetricData;
  public orders: MetricData;
  public ctr: MetricData;
  public cvr: MetricData;
  public cpc: MetricData;
  public spend: MetricDataWithPreviousDays;
  public sales: MetricDataWithPreviousDays;
  public acos: MetricData;
  public roas: MetricData;
  public rpc: MetricData;
  public cpa: MetricData;
  public aov: MetricData;
  public cpm: MetricData;

  constructor(args: CommonMetricsCreateArguments) {
    this.impressions = args.impressions;
    this.clicks = args.clicks;
    this.orders = args.orders;
    this.ctr = args.ctr;
    this.cvr = args.cvr;
    this.cpc = args.cpc;
    this.spend = args.spend;
    this.sales = args.sales;
    this.acos = args.acos;
    this.roas = args.roas;
    this.rpc = args.rpc;
    this.cpa = args.cpa;
    this.aov = args.aov;
    this.cpm = args.cpm;
  }

  public static fromResponse(response: CommonMetricsDTO): CommonMetrics {
    return new CommonMetrics({
      impressions: response.impressions,
      clicks: response.clicks,
      orders: response.orders,
      ctr: response.ctr,
      cvr: response.cvr,
      cpc: response.cpc,
      spend: response.spend,
      sales: response.sales,
      acos: response.acos,
      roas: response.roas,
      rpc: response.rpc,
      cpa: response.cpa,
      aov: response.aov,
      cpm: response.cpm,
    });
  }

  public static withZeroMetrics(): CommonMetrics {
    const zeroMetrics = new CommonMetrics({
      impressions: EMPTY_METRIC_DATA,
      clicks: EMPTY_METRIC_DATA,
      orders: EMPTY_METRIC_DATA,
      ctr: EMPTY_METRIC_DATA,
      cvr: EMPTY_METRIC_DATA,
      cpc: EMPTY_METRIC_DATA,
      spend: EMPTY_METRIC_DATA_WITH_PREVIOUS_DAYS,
      sales: EMPTY_METRIC_DATA_WITH_PREVIOUS_DAYS,
      acos: EMPTY_METRIC_DATA,
      roas: EMPTY_METRIC_DATA,
      rpc: EMPTY_METRIC_DATA,
      cpa: EMPTY_METRIC_DATA,
      aov: EMPTY_METRIC_DATA,
      cpm: EMPTY_METRIC_DATA,
    });
    return zeroMetrics;
  }
}

export interface CommonMetricsCreateArguments {
  impressions: MetricData;
  clicks: MetricData;
  orders: MetricData;
  ctr: MetricData;
  cvr: MetricData;
  cpc: MetricData;
  spend: MetricDataWithPreviousDays;
  sales: MetricDataWithPreviousDays;
  acos: MetricData;
  roas: MetricData;
  rpc: MetricData;
  cpa: MetricData;
  aov: MetricData;
  cpm: MetricData;
}

export const calculatedFieldDependencies: Partial<Record<MetricField, MetricField[]>> = {
  [MetricField.CTR]: [MetricField.CLICKS, MetricField.IMPRESSIONS],
  [MetricField.CVR]: [MetricField.ORDERS, MetricField.CLICKS],
  [MetricField.CPC]: [MetricField.SPEND, MetricField.CLICKS],
  [MetricField.ACOS]: [MetricField.SPEND, MetricField.SALES],
  [MetricField.ROAS]: [MetricField.SALES, MetricField.SPEND],
  [MetricField.RPC]: [MetricField.SALES, MetricField.CLICKS],
  [MetricField.CPA]: [MetricField.SPEND, MetricField.ORDERS],
  [MetricField.AOV]: [MetricField.SALES, MetricField.ORDERS],
  [MetricField.CPM]: [MetricField.SPEND, MetricField.IMPRESSIONS],
};
