import { getConfigForMetric, getMetricConfigByColId, getSentimentByMetric } from '@/components/metrics/MetricsConfig';
import { MetricField, calculatedFieldDependencies } from '@/components/metrics/models/CommonMetricsModel';
import useFormatting, { FormattingParams } from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { CampaignAdType, PlacementType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { MetricData } from '@/modules/targeting/models/TargetsModel';
import { SentimentDirection, getCampaignAdTypeColor, getPlacementTypeColor } from '@/types/colors.enum';
import { IAggFuncParams, ICellRendererParams, IRowNode } from 'ag-grid-enterprise';
import { isNil } from 'lodash-es';
import { ITextCellRendererParams } from '../cells/TextCellRenderer';
import { ColumnId } from '../columns/columns.enum';
import {
  calculateMetricDataACOS,
  calculateMetricDataAOV,
  calculateMetricDataCPA,
  calculateMetricDataCPC,
  calculateMetricDataCPM,
  calculateMetricDataCTR,
  calculateMetricDataCVR,
  calculateMetricDataROAS,
  calculateMetricDataRPC,
} from '../helpers';

const useColDefsFunctions = () => {
  const { formatWithThousandsSeparator, getLongFormatterForMetricField } = useFormatting();
  const { t } = useTranslation();

  const metricsDataComparator = (valueA: MetricData, valueB: MetricData): number => {
    if (valueA[0] > valueB[0]) {
      return 1;
    } else if (valueA[0] < valueB[0]) {
      return -1;
    } else {
      return 0;
    }
  };

  function getCalculatedMetricAggData(metricField: MetricField, aggData: Record<string, MetricData>): MetricData {
    switch (metricField) {
      case MetricField.CTR:
        return calculateMetricDataCTR(aggData);
      case MetricField.CVR:
        return calculateMetricDataCVR(aggData);
      case MetricField.CPC:
        return calculateMetricDataCPC(aggData);
      case MetricField.ACOS:
        return calculateMetricDataACOS(aggData);
      case MetricField.ROAS:
        return calculateMetricDataROAS(aggData);
      case MetricField.RPC:
        return calculateMetricDataRPC(aggData);
      case MetricField.CPA:
        return calculateMetricDataCPA(aggData);
      case MetricField.AOV:
        return calculateMetricDataAOV(aggData);
      case MetricField.CPM:
        return calculateMetricDataCPM(aggData);
      default:
        return [0, 0];
    }
  }

  function createSumAggDataForFields(fields: MetricField[], rowNodes: IRowNode[]): Record<string, MetricData> {
    const aggData: Record<string, MetricData> = {};

    // Init so at least zero values and key is present
    for (const field of fields) {
      aggData[field] = [0, 0];
    }

    for (const rowNode of rowNodes) {
      for (const field of fields) {
        const colId = getConfigForMetric(field).colId;

        if (isNil(rowNode.data?.[colId])) {
          console.error(`Column ${colId} not found in row node data for row node ${rowNode}`);
          break;
        }
        aggData[field][0] += rowNode.data[colId][0];
        aggData[field][1] += rowNode.data[colId][1];
      }
    }

    return aggData;
  }

  function metricsDataAggFunc(params: IAggFuncParams): MetricData {
    const columnId = params.colDef?.field as ColumnId;
    const metricField = columnId ? getMetricConfigByColId(columnId)?.key : null;

    if (!metricField) {
      return [0, 0];
    }

    if (getConfigForMetric(metricField).isCalculatedMetric) {
      const deps = calculatedFieldDependencies[metricField];
      if (!deps) return [0, 0];

      const depsAggData = createSumAggDataForFields(deps, params.rowNode.allLeafChildren ?? []);

      return getCalculatedMetricAggData(metricField, depsAggData);
    }

    const metricsArray = params.values as MetricData[];

    return metricsArray.reduce(
      (accumulator: MetricData, current: MetricData) => {
        return [accumulator[0] + current[0], accumulator[1] + current[1]];
      },
      [0, 0],
    );
  }

  const getMetricFieldChangePercentageCellRendererParams = (params: ICellRendererParams, formattingParams?: FormattingParams) => {
    const columnId = params.colDef?.field as ColumnId;
    const metricField = columnId ? (getMetricConfigByColId(columnId)?.key ?? MetricField.IMPRESSIONS) : MetricField.IMPRESSIONS;

    return {
      currentValueFormatter: params.colDef
        ? (number: number | null | undefined) => {
            return getLongFormatterForMetricField(metricField)(number, formattingParams);
          }
        : formatWithThousandsSeparator,
      sentimentDirection: params.colDef ? getSentimentByMetric(metricField) : SentimentDirection.SYNCED,
      value: params.value,
      comparisonUnit: params.context?.comparisonUnit,
      isComparisonDataMissing: params.context?.isComparisonDataMissing,
    };
  };

  const getCampaignAdTypeCellRendererParams = (): ITextCellRendererParams => {
    return {
      valueToString: (key: string) => (key ? t(`optimizer_page.ad_type.${key}`) : ''),
      valueToColor: (key: string) => getCampaignAdTypeColor(CampaignAdType[key as keyof typeof CampaignAdType]),
      valueToTooltip: (key: string) => {
        const type = CampaignAdType[key as keyof typeof CampaignAdType];
        switch (type) {
          case CampaignAdType.PRODUCTS:
            return t(`optimizer_page.ad_type_long.${type}`);
          case CampaignAdType.BRANDS:
            return t(`optimizer_page.ad_type_long.${type}`);
          case CampaignAdType.DISPLAY:
            return t(`optimizer_page.ad_type_long.${type}`);
          default:
            return '';
        }
      },
    };
  };

  const getPlacementTypeCellRendererParams = (): ITextCellRendererParams => {
    return {
      valueToString: (key: string) => (key ? t(`optimizer_page.bidding_entity.${key}`) : ''),
      valueToColor: (key: string) => getPlacementTypeColor(PlacementType[key as keyof typeof PlacementType]),
      // valueToTooltip: (key: string) => {
      //   const type = PlacementType[key as keyof typeof PlacementType];
      //   switch (type) {
      //     case PlacementType.PLACEMENT_PRODUCT_PAGE:
      //       return 'Product Page';
      //     case PlacementType.PLACEMENT_REST_OF_SEARCH:
      //       return 'Rest of Search';
      //     case PlacementType.PLACEMENT_TOP:
      //       return 'Top of Search';
      //     default:
      //       return '';
      //   }
      // Missing Home etc
      // },
    };
  };

  return {
    metricsDataComparator,
    metricsDataAggFunc, // TODO: move to useAggregators
    getMetricFieldChangePercentageCellRendererParams,
    getCampaignAdTypeCellRendererParams,
    getPlacementTypeCellRendererParams,
    getCalculatedMetricAggData,
  };
};

export default useColDefsFunctions;
