import { ReactECharts } from '@/components/chart/ReactEcharts';
import { DAY_ICON_SVG_PATH, WEEK_ICON_SVG_PATH } from '@/components/chart/icons';
import { TimeUnit } from '@/components/chart/types';
import { aggregateWeekly } from '@/components/chart/utils';
import CollapsibleCopyableDetails from '@/components/feedback/CollapsibleCopyableDetails';
import useFormatting from '@/hooks/useFormatting';
import { useTranslation } from '@/lib';
import { TimelineModel } from '@/modules/optimizer/components/timeline/models/TimelineModel';
import { Alert, AlertTitle, Card, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import {
  BarSeriesOption,
  ECElementEvent,
  LineSeriesOption,
  TooltipComponentFormatterCallbackParams,
  YAXisComponentOption,
  type ECharts,
  type EChartsOption,
  type TooltipComponentOption,
} from 'echarts';
import { isArray } from 'lodash-es';
import { FunctionComponent, useCallback, useRef, useState } from 'react';
import LoadingBlock from '../feedback/LoadingBlock';
import { MetricColor, getConfigForMetric } from '../metrics/MetricsConfig';
import { MetricField } from '../metrics/models/CommonMetricsModel';

const GRAPH_HEIGHT = 380;

interface MetricTimelineChartProps {
  selectedMetrics: MetricField[];
  selectedMetricColors: MetricColor[];
  timelineData: TimelineModel | undefined;
  isLoading: boolean;
  error: Error | null;
  isError: boolean;
}

const CampaignTimelineChart: FunctionComponent<MetricTimelineChartProps> = ({
  selectedMetrics,
  selectedMetricColors,
  timelineData,
  isLoading,
  error,
  isError,
}) => {
  const hoveredSeries = useRef<string | undefined>();
  const hoveredPoint = useRef<number | undefined>();

  const { t } = useTranslation();

  const [view, setView] = useState<TimeUnit>(TimeUnit.DAY);

  const toggleView = () => {
    setView(view === TimeUnit.DAY ? TimeUnit.WEEK : TimeUnit.DAY);
  };

  const { getLongFormatterForMetricField, getShortFormatterForMetricField, getWeekday } = useFormatting();

  const getChartOptions = useCallback(
    (data: TimelineModel): EChartsOption => {
      const series: (LineSeriesOption | BarSeriesOption)[] = [];
      const yAxis: YAXisComponentOption[] = [];

      const processedData = view === TimeUnit.WEEK ? aggregateWeekly(data) : data;

      const xAxisData = processedData.xAxisData;
      const yAxisData = processedData.yAxisData.filter((item) => selectedMetrics.includes(item.key));

      yAxisData.forEach((dataItem, index: number) => {
        const metricConfig = getConfigForMetric(dataItem.key);
        if (!metricConfig) {
          return;
        }

        const color = selectedMetricColors.find((color) => color.key === dataItem.key)?.color;

        if (metricConfig.chartSeriesType === 'bar') {
          const barSeries: BarSeriesOption = {
            id: dataItem.key,
            name: metricConfig.title,
            type: 'bar',
            yAxisIndex: index,
            data: dataItem.values,
            color: color,
            emphasis: {
              focus: 'none',
            },
          };
          series.push(barSeries);
        } else if (metricConfig.chartSeriesType === 'line') {
          const lineSeries: LineSeriesOption = {
            id: dataItem.key,
            name: metricConfig.title,
            type: 'line',
            yAxisIndex: index,
            data: dataItem.values,
            color: color,
            symbol: 'emptyCircle',
            symbolSize: 6,
            emphasis: {
              focus: 'none',
            },
            showSymbol: true,
          };
          series.push(lineSeries);
        }

        yAxis.push({
          name: metricConfig?.title,
          type: 'value',
          position: index === 0 ? 'left' : 'right',
          offset: index === 0 ? 0 : yAxisData.length === 2 ? 0 : 60 * (index - 1), // Conditional offset based on number of Y-axes
          nameTextStyle: {
            fontWeight: 'bold', // Make axis name bold
            fontFamily: 'Roboto',
          },
          axisLabel: {
            fontWeight: 'bold', // Make axis labels bold
            fontFamily: 'Roboto',
            formatter: (value: number) => getShortFormatterForMetricField(dataItem.key)(value),
          },
          axisPointer: {
            // Configure hover formatting for the Y-axis
            label: {
              show: true,
              formatter: (params) => getShortFormatterForMetricField(dataItem.key)(params.value as number),
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: color,
            },
          },
        });
      });
      return {
        xAxis: {
          type: 'category',
          data: xAxisData,
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            formatter: function (value: string) {
              const date = dayjs(value);
              return view === TimeUnit.WEEK ? `${value}` : date.format('MMM DD');
            },
            fontWeight: 'bolder',
            fontFamily: 'Roboto',
          },
          splitLine: {
            show: false, // Hide vertical lines
          },
        },
        yAxis: yAxis,
        series: series,
        grid: {
          left: 65,
          right: 55 * yAxisData.length,
          top: 45,
          bottom: 45,
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          right: '10px',
          top: '10px',
          feature: {
            dataView: { readOnly: false, buttonColor: '#2563EB' },
            // magicType: { type: ['line', 'bar'] },
            //restore: {},
            saveAsImage: { name: 'Timeline Chart' },
            myDayWeekToggle: {
              // Custom buttons need to begin with 'my'
              show: true,
              title: 'Toggle Daily/Weekly',
              icon: view === TimeUnit.WEEK ? WEEK_ICON_SVG_PATH : DAY_ICON_SVG_PATH,
              onclick: () => {
                toggleView();
              },
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
          formatter: function (params: TooltipComponentFormatterCallbackParams): string {
            if (isArray(params)) {
              let result = `<b>${params[0].name}</b>` + '<br/>'; // Add the x-axis series name (date)
              const description = view === TimeUnit.DAY ? `${getWeekday(params[0].name)}<br/>` : '';

              result += description;
              params.forEach((param) => {
                const formattedValue = getLongFormatterForMetricField(param.seriesId as MetricField)(param.value as number);
                if (param.seriesName === hoveredSeries.current) {
                  result += `<b>${param.marker}${param.seriesName}: ${formattedValue}</b><br/>`;
                } else {
                  result += `${param.marker}${param.seriesName}: ${formattedValue}<br/>`;
                }
              });
              return result;
            } else {
              return '';
            }
          },
        } as TooltipComponentOption,
      };
    },
    [view, selectedMetrics],
  );

  function onChartReady(instance: ECharts) {
    instance.on('mouseover', (params: ECElementEvent) => {
      hoveredSeries.current = params.seriesName;
      hoveredPoint.current = params.dataIndex;
    });
    instance.on('mouseout', () => {
      hoveredSeries.current = undefined;
      hoveredPoint.current = undefined;
    });
  }

  const renderError = () => (
    <Alert severity="error">
      <AlertTitle>Oops!</AlertTitle>
      <div className="flex w-full flex-col">
        <Typography variant="body1">{t('messages.errors.generic')}</Typography>

        <CollapsibleCopyableDetails headerText={'Details'} message={(error as Error).message} />
      </div>
    </Alert>
  );

  return (
    <Card className="rounded-xl py-0 ">
      {isError ? renderError() : null}
      {isLoading && !timelineData && selectedMetrics.length > 0 ? (
        <div className="relative w-full" style={{ height: GRAPH_HEIGHT }}>
          <Skeleton variant="rectangular" width="100%" height={GRAPH_HEIGHT} />
          <div className="absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2">
            <LoadingBlock />
          </div>
        </div>
      ) : timelineData && timelineData.xAxisData.length > 0 && timelineData.yAxisData.length > 0 && selectedMetrics.length > 0 ? (
        <ReactECharts
          option={getChartOptions(timelineData)}
          style={{ height: `${GRAPH_HEIGHT}px`, paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
          onChartReady={onChartReady}
          settings={{ notMerge: true }}
        />
      ) : (
        !isError && (
          <div className="text-center">
            <Typography variant="overline" className="font-bold text-primary-600">
              select at least one metric to display the timeline graph
            </Typography>
          </div>
        )
      )}
    </Card>
  );
};

export default CampaignTimelineChart;
