import UnitToggleButton from '@/components/buttons/UnitToggleButton';
import { FilterBuilderDialog } from '@/components/filter-builder/FilterBuilderDialog';
import { FilterChipRow } from '@/components/filter-builder/FilterChipRow';
import { SearchBox } from '@/components/filter-builder/SearchBox';
import { AlFilterModel, CampaignNameFilterModel } from '@/components/filter-builder/models/AlFilterModel';
import { FilterKey } from '@/components/filter-builder/types/FilterKey';
import useComparisonMissing from '@/components/grid/hooks/useComparisonMissing';
import { ComparisonUnit } from '@/components/grid/types';
import DateRangeButton from '@/modules/application/components/date-range-picker/DateRangeButton';
import useAvailableCampaignFilters from '@/modules/campaigns/hooks/useAvailableCampaignFilters';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { Button, Card } from '@mui/material';
import { FunctionComponent, useRef, useState } from 'react';
import { CampaignsWithTimeline } from '../api/campaign/campaign-contracts';
import { useOptimizerContext } from '../contexts/OptimizerContext';

// TODO: use common interface for all timeline responses instead
interface OptimizerFilterBarProps {
  campaignsWithTimeline: CampaignsWithTimeline | undefined;
}

const OptimizerFilterBar: FunctionComponent<OptimizerFilterBarProps> = ({ campaignsWithTimeline }) => {
  const filtersButtonRef = useRef<HTMLButtonElement | null>(null); // reference to the filters button to open the popover
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false); // state to control the popover
  const { filters, setFilters, setFilterValue, setFilterValues, comparisonUnit, setComparisonUnit } = useOptimizerContext();
  const { getComparisonDataMissingMessage } = useComparisonMissing({});

  const onFilterChipClicked = () => {
    setIsFilterDialogOpen((previousValue) => !previousValue);
  };

  const onFilterDeleteButtonClicked = (filter: AlFilterModel) => {
    setFilters((previousFilters) => previousFilters.filter((f) => f.key !== filter.key));
  };

  const { availableCampaignFilters } = useAvailableCampaignFilters();

  const handleComparisonUnitChange = (event: React.MouseEvent<HTMLElement>, newAlignment: ComparisonUnit) => {
    setComparisonUnit(newAlignment);
  };

  function onFiltersChanged(newFilters: AlFilterModel[]) {
    setFilters(newFilters);
  }

  return (
    <Card className="flex flex-row items-center gap-x-4 p-2 pb-3 rounded-none rounded-tr-xl rounded-tl-xl -mb-1 ">
      <SearchBox
        filterKey={FilterKey.CAMPAIGN_NAME}
        newFilterModel={() => new CampaignNameFilterModel()}
        placeholder="Search Campaign"
        filters={filters}
        setFilterValue={setFilterValue}
        setFilters={setFilters}
      />

      <div className="flex flex-row">
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={onFilterChipClicked}
          startIcon={<FilterListRoundedIcon />}
          ref={filtersButtonRef}
          className="mr-2 flex-shrink-0"
        >
          Adjust Filters
        </Button>

        <FilterBuilderDialog
          buttonRef={filtersButtonRef}
          setIsOpen={setIsFilterDialogOpen}
          isOpen={isFilterDialogOpen}
          availableFilters={availableCampaignFilters}
          defaultFilterKey={FilterKey.CAMPAIGN_ID}
          appliedFilters={filters}
          onFiltersChanged={onFiltersChanged}
        />

        <FilterChipRow
          filters={filters}
          onFilterDeleteButtonClicked={onFilterDeleteButtonClicked}
          onFilterChipClicked={onFilterChipClicked}
        />
      </div>
      <div className="flex flex-grow"></div>

      <div className="flex h-full flex-col justify-center items-center">
        <DateRangeButton
          title="Optimization Range"
          filters={filters}
          setFilterValues={setFilterValues}
          tooltip="Date range used to calculate bid optimizations"
          comparisonRangeWarning={getComparisonDataMissingMessage(campaignsWithTimeline?.isComparisonDataMissing ?? false)}
        />
      </div>

      <UnitToggleButton handleComparisonUnitChange={handleComparisonUnitChange} comparisonUnit={comparisonUnit} />
    </Card>
  );
};

export default OptimizerFilterBar;
