import { Navigate, RouteObject } from 'react-router-dom';
import { RegistrationLayout } from '@/modules/registration/layouts/RegistrationLayout';
import RegistrationStepCreateAccountPage from '@/modules/registration/pages/RegistrationStepCreateAccountPage';
import RegistrationStepAboutYouPage from '../pages/RegistrationStepAboutYouPage';

export const REGISTRATION_ROUTES: RouteObject[] = [
  {
    path: 'register',
    element: <RegistrationLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="create-your-account" replace />,
      },
      {
        path: 'create-your-account',
        element: <RegistrationStepCreateAccountPage />,
      },
      {
        path: 'about-you',
        element: <RegistrationStepAboutYouPage />,
      },
    ],
  },
];
