import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isNil, throttle } from 'lodash-es';
import { useDashboardContextValue } from '../contexts/DashboardContextProvider';
import { dashboardDataService } from '../api/dashboard/data/dashboard-data.service';

export function useDashboardQueries() {
  const {
    dates,
    comparisonDates,
    dashboard,
    dashboardDateFilters,
    setWidgetIdsRequestingData,
    widgetIdsRequestingData,
    saveDashboard,
    dashboardVersion,
  } = useDashboardContextValue((context) => ({
    dates: context.dates,
    comparisonDates: context.comparisonDates,
    dashboard: context.dashboard,
    dashboardDateFilters: context.dashboardDateFilters,
    setWidgetIdsRequestingData: context.setWidgetIdsRequestingData,
    widgetIdsRequestingData: context.widgetIdsRequestingData,
    saveDashboard: context.saveDashboard,
    dashboardVersion: context.dashboardVersion,
  }));
  const [isLoading, setIsLoading] = useState(true);
  // const previousVersion = useRef(dashboardVersion);

  const {
    data: dashboardData,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: [
      dashboard ? dashboard.dto.id : 'unknown',
      'DASHBOARD_DATA',
      // dates.map((d) => d.format(DATE_FORMAT)),
      // comparisonDates.map((d) => d.format(DATE_FORMAT)),
    ],
    queryFn: async () => {
      if (!dashboard) {
        throw new Error('Dashboard not found');
      }

      const result = await dashboardDataService.getById(dashboard.dto.id);
      console.log({
        result,
      });

      setIsLoading(false);
      setWidgetIdsRequestingData(new Set<string>());

      if (result.isSuccess) {
        return result.payload;
      } else {
        throw new Error('Error loading dashboard data');
      }
    },
    enabled: !isNil(dashboard),
  });

  // Throttle the refetch function
  const throttledRefetchDashboardData = throttle(refetch, 2500, {
    leading: true,
    trailing: false,
  });

  const refetchForWidgetWithId = (widgetId: string) => {
    console.log('Refetching for widget with id: ', widgetId);

    setWidgetIdsRequestingData((prev) => {
      if (prev.has(widgetId)) {
        return prev;
      } else {
        prev.add(widgetId);
        return new Set(prev);
      }
    });
    throttledRefetchDashboardData();
  };

  function getDataForWidgetWithId(widgetId: string) {
    return dashboardData?.getWidgetData(widgetId);
  }

  function refetchDashboardData() {
    // set all widgets loading
    setWidgetIdsRequestingData(new Set(dashboard?.dto.widgets.map((w) => w.id) || []));
    throttledRefetchDashboardData();
  }

  return {
    isLoading,
    dashboardData,
    refetchForWidgetWithId,
    // isFetching,
    throttledRefetchDashboardData,
    widgetIdsRequestingData,
    getDataForWidgetWithId,
    refetchDashboardData,
  };
}

// Conext value hook
