import InfoMessage from '@/components/feedback/InfoMessage';
import LoadingOverlay from '@/components/feedback/LoadingOverlay';
import { AlMultiSelectOptionModel } from '@/components/filter-builder/models/AlMultiSelectOptionModel';
import { AlMultiSelect } from '@/components/form/AlMultiSelect';
import { AlMultiTextInput } from '@/components/form/AlMultiTextInput';
import { useHelperComponents } from '@/hooks/useHelperComponents';
import { useTranslation } from '@/lib';
import { UpdateResponseDTO } from '@/modules/application/components/UpdateResponseModal';
import { EXAMPLE_VALID_ASIN, cleanAsinsReturnErrorMessage } from '@/modules/application/utils';
import { EnabledPausedArchivedState, TargetingType } from '@/modules/optimizer/api/campaign/campaign-contracts';
import { CampaignService, campaignService } from '@/modules/optimizer/api/campaign/campaign-service';
import { AdGroupModel } from '@/modules/optimizer/api/campaign/models/AdGroupModel';
import {
  CreateNegativesParams,
  SearchTermModel,
  createNegativesParamKeyToNegativeMatchType,
} from '@/modules/search-terms/models/SearchTermModel';
import { TargetEntityType } from '@/modules/targeting/api/targets-contracts';
import { useActiveTeamContext } from '@/modules/teams/contexts/ActiveTeamContext';
import { TargetEntityTypeColors, TargetingTypeColors } from '@/types/colors.enum';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { CAMPAIGN_NEGATIVES, NegativeMatchType } from '../../api/negative-targets-contracts';
import { invalidateProfile_negativeTargetingQueryKeys, negativeTargetingService } from '../../api/negative-targets-service';
import { CampaignToAdGroups } from '../../models/CampaignToAdGroupModel';
import { CreateNegativeModel } from '../../models/CreateNegativeModel';

export interface CreateNegativesDirectModalPreset {
  sourceCampaignIds: string[];
}

interface CreateNegativesDirectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onApplied: (applyResponse: React.SetStateAction<UpdateResponseDTO | undefined>) => void;
  preset?: CreateNegativesDirectModalPreset;
}

interface WarningIndicators {
  campaignCountFilteredOutDueNoAdGroups: number;
  presetNoCampaignsLeftAfterEmptyAdGroupRemoved: boolean;
  notAllNegativesCanBeMadeForSelectedCampaigns: boolean;
}

export const CreateNegativesDirectModal: FunctionComponent<CreateNegativesDirectModalProps> = ({ isOpen, onClose, onApplied, preset }) => {
  const { activeProfile } = useActiveTeamContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { createMultilineMessageComponent } = useHelperComponents();
  const [negativeKeywordsToCreate, setNegativeKeywordsToCreate] = useState<CreateNegativeModel[]>([]);

  const [warningsIndicators, setWarningsIndicators] = useState<WarningIndicators>({
    campaignCountFilteredOutDueNoAdGroups: 0,
    presetNoCampaignsLeftAfterEmptyAdGroupRemoved: false,
    notAllNegativesCanBeMadeForSelectedCampaigns: false,
  });

  // DATA
  const { data: campaignToAdGroupsRecord, isLoading: isCampaignToAdGroupsRecord } = useQuery({
    queryKey: CampaignService.getCampaignsToAdGroupsQueryKey(activeProfile?.id),
    queryFn: async () => {
      const result = await campaignService.getCampaignToAdGroups();
      if (result.isSuccess) {
        // Remove archived campaigns and ad groups
        const campaignToAdGroupsRecord: Record<string, CampaignToAdGroups> = result.payload
          .filter((campaign) => campaign.state !== EnabledPausedArchivedState.ARCHIVED)
          .reduce(
            (acc, campaign) => {
              const adGroupsRecord: Record<string, AdGroupModel> = {};
              Object.entries(campaign.adGroupIdToAdGroup).forEach(([adGroupId, adGroup]) => {
                if (adGroup.state !== EnabledPausedArchivedState.ARCHIVED) {
                  adGroupsRecord[adGroupId] = adGroup;
                }
              });

              campaign.adGroupIdToAdGroup = adGroupsRecord;
              acc[campaign.id] = campaign;
              return acc;
            },
            {} as Record<string, CampaignToAdGroups>,
          );

        return campaignToAdGroupsRecord;
      } else {
        toast.error(`Error loading groups ${result.message}`);
      }
    },
    enabled: !isNil(activeProfile),
  });

  const adGroupIdToAdGroupRecord = useMemo(() => {
    if (!campaignToAdGroupsRecord) {
      return {};
    }

    const record: Record<string, AdGroupModel> = {};

    Object.values(campaignToAdGroupsRecord).forEach((campaign) => {
      Object.entries(campaign.adGroupIdToAdGroup).forEach(([adGroupId, adGroup]) => {
        record[adGroupId] = adGroup;
      });
    });

    return record;
  }, [campaignToAdGroupsRecord]);

  // Checked negatives
  const [negativeMatchOptions, setNegativeMatchOptions] = useState<CreateNegativesParams>({
    campaignNegativeExact: false,
    campaignNegativePhrase: false,
    campaignNegativeProductTarget: false,
    adGroupNegativeExact: false,
    adGroupNegativePhrase: false,
    adGroupNegativeProductTarget: false,
  });

  useEffect(() => {
    // Preset warnings, only when preset is present
    if (!campaignToAdGroupsRecord || !preset?.sourceCampaignIds) return;

    // Warning setting
    const warningsIndicatorsToSet = {
      campaignCountFilteredOutDueNoAdGroups: 0,
      presetNoCampaignsLeftAfterEmptyAdGroupRemoved: false,
      notAllNegativesCanBeMadeForSelectedCampaigns: false,
    };

    const campaignsWithAdGroupsIds = preset.sourceCampaignIds.filter(
      (campaignId) =>
        !isNil(
          campaignToAdGroupsRecord[campaignId] &&
            !isNil(campaignToAdGroupsRecord[campaignId].adGroupIdToAdGroup) &&
            Object.keys(campaignToAdGroupsRecord[campaignId].adGroupIdToAdGroup).length > 0,
        ),
    );

    if (campaignsWithAdGroupsIds.length != preset.sourceCampaignIds.length) {
      warningsIndicatorsToSet.campaignCountFilteredOutDueNoAdGroups = preset.sourceCampaignIds.length - campaignsWithAdGroupsIds.length;
    }

    if (campaignsWithAdGroupsIds.length == 0) {
      warningsIndicatorsToSet.presetNoCampaignsLeftAfterEmptyAdGroupRemoved = true;
    }

    setWarningsIndicators(warningsIndicatorsToSet);

    // Set preset campaigns that have ad groups as chosen campaigns
    setSelectedCampaignIds(campaignsWithAdGroupsIds);
  }, [campaignToAdGroupsRecord, preset, negativeMatchOptions]);

  useEffect(() => {
    // Can't create any negatives for these campaigns
    if ((isSomeKeywordsChecked && !isSomeAdGroupsKeywordType) || (isSomeProductTargetsChecked && !isSomeAdGroupsProductTargetType)) {
      setWarningsIndicators((prev) => ({ ...prev, notAllNegativesCanBeMadeForSelectedCampaigns: true }));
      return;
    }

    if (!campaignToAdGroupsRecord || negativeKeywordsToCreate.length == 0) return;

    // Haven't yet filled in, don't warn
    if (
      (selectedNegativeKeywords.length == 0 && isSelectNegativeKeywordsVisible) ||
      (selectedNegativeProductTargetAsins.length == 0 && isSelectProductAsinsVisible)
    ) {
      setWarningsIndicators((prev) => ({ ...prev, notAllNegativesCanBeMadeForSelectedCampaigns: false }));
      return;
    }

    // Check if for any of the selected negatives there are campaigns for which you can't create such negatives
    // do this by checking which are not in the negativeKeywordsToCreate list

    const campaignIdToNegMatches: Record<string, NegativeMatchType[]> = {};
    for (const neg of negativeKeywordsToCreate) {
      if (CAMPAIGN_NEGATIVES.includes(neg.negativeMatchType)) {
        if (!campaignIdToNegMatches[neg.campaignId]) {
          campaignIdToNegMatches[neg.campaignId] = [];
        }

        campaignIdToNegMatches[neg.campaignId].push(neg.negativeMatchType);
      }
    }

    // Selected negs to NegativeMatchType[]
    const userSelectedNegativeOptionsKeys = Object.keys(negativeMatchOptions).filter(
      (key) => negativeMatchOptions[key as keyof CreateNegativesParams],
    );
    const selectedNegsAsMatchTypes = userSelectedNegativeOptionsKeys.map((key) => {
      return createNegativesParamKeyToNegativeMatchType[key as keyof CreateNegativesParams];
    });

    // Compare made matches with selected negatives
    for (const campaignId in campaignIdToNegMatches) {
      const negMatches = campaignIdToNegMatches[campaignId];
      if (negMatches.every((negMatch) => selectedNegsAsMatchTypes.includes(negMatch))) {
        continue;
      } else {
        setWarningsIndicators((prev) => ({ ...prev, notAllNegativesCanBeMadeForSelectedCampaigns: true }));
        return;
      }
    }

    setWarningsIndicators((prev) => ({ ...prev, notAllNegativesCanBeMadeForSelectedCampaigns: false }));
  }, [negativeKeywordsToCreate]);

  const handleNegativeMatchOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (type === 'checkbox') {
      setNegativeMatchOptions({
        ...negativeMatchOptions,
        [name]: checked,
      });
    } else {
      setNegativeMatchOptions({
        ...negativeMatchOptions,
        [name]: value,
      });
    }
  };

  // Negative Keywords input
  const [selectedNegativeKeywords, setSelectedNegativeKeywords] = useState<string[]>([]);

  // Negative Product Targets input
  const [selectedNegativeProductTargetAsins, setSelectedNegativeProductTargetAsins] = useState<string[]>([]);
  function setNegativeProductTargetAsinsWithValidationAndFormatting(asins: string[]) {
    const { validAsins, errorMessage } = cleanAsinsReturnErrorMessage(asins);
    if (errorMessage) {
      toast.error(errorMessage);
    }
    setSelectedNegativeProductTargetAsins(validAsins);
  }

  // Controlling
  const isSomeKeywordsChecked = [
    negativeMatchOptions.campaignNegativeExact,
    negativeMatchOptions.campaignNegativePhrase,
    negativeMatchOptions.adGroupNegativeExact,
    negativeMatchOptions.adGroupNegativePhrase,
  ].some(Boolean);

  const isSomeProductTargetsChecked = [
    negativeMatchOptions.campaignNegativeProductTarget,
    negativeMatchOptions.adGroupNegativeProductTarget,
  ].some(Boolean);

  useEffect(() => {
    if (!isSomeKeywordsChecked) {
      setSelectedNegativeKeywords([]);
    }
  }, [isSomeKeywordsChecked]);

  useEffect(() => {
    if (!isSomeProductTargetsChecked) {
      setSelectedNegativeProductTargetAsins([]);
    }
  }, [isSomeProductTargetsChecked]);

  const isSomeAdGroupsChecked = [
    negativeMatchOptions.adGroupNegativeExact,
    negativeMatchOptions.adGroupNegativePhrase,
    negativeMatchOptions.adGroupNegativeProductTarget,
  ].some(Boolean);

  const isSomeCampaignsChecked = [
    negativeMatchOptions.campaignNegativeExact,
    negativeMatchOptions.campaignNegativePhrase,
    negativeMatchOptions.campaignNegativeProductTarget,
  ].some(Boolean);

  useEffect(() => {
    if (!isSomeAdGroupsChecked) {
      setSelectedAdGroupIds([]);
    }
  }, [isSomeAdGroupsChecked]);

  // CAMPAIGN SELECT
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>([]);
  // All profile campaigns
  const campaignSelectOptions = useMemo(() => {
    if (!campaignToAdGroupsRecord) return [];

    if (preset?.sourceCampaignIds) {
      return preset.sourceCampaignIds
        .filter((campaignId) => !isNil(campaignToAdGroupsRecord[campaignId]))
        .map((campaignId) => {
          const campaign = campaignToAdGroupsRecord[campaignId];
          const targetingType = t(`optimizer_page.targeting_type.${campaign.targetingType}`);
          const color = TargetingTypeColors[campaign.targetingType];
          return new AlMultiSelectOptionModel(campaign.name, campaign.id, undefined, targetingType, color);
        });
    }

    return Object.keys(campaignToAdGroupsRecord)
      .map((campaignId) => {
        const campaign = campaignToAdGroupsRecord[campaignId];
        const targetingType = t(`optimizer_page.targeting_type.${campaign.targetingType}`);
        const color = TargetingTypeColors[campaign.targetingType];
        return new AlMultiSelectOptionModel(campaign.name, campaign.id, undefined, targetingType, color);
      })
      .filter((option) => {
        const campaign = campaignToAdGroupsRecord[option.id];

        // Intermediary model to use the existing flow
        const selectedSearchTerm = SearchTermModel.selectedSearchTermFromNegativesData({
          selectedCampaignIds: [campaign.id],
          selectedAdGroupIds: campaign.adGroupIdToAdGroup ? Object.keys(campaign.adGroupIdToAdGroup) : [],
          selectedNegativeKeywords: ['kw'], // random valid values for checking
          selectedNegativeProductTargetAsins: [EXAMPLE_VALID_ASIN], // random valid values for checking
          campaignToAdGroupsRecord,
        });

        return CreateNegativeModel.fromSelectedSearchTermArrayAndParams(selectedSearchTerm, negativeMatchOptions).length > 0;
      });
  }, [campaignToAdGroupsRecord, isSomeAdGroupsChecked, isSomeProductTargetsChecked, negativeMatchOptions, isSomeCampaignsChecked]);

  const isOnlyManualCampaignsChosen =
    campaignToAdGroupsRecord &&
    selectedCampaignIds.map((id) => campaignToAdGroupsRecord[id]?.targetingType).every((type) => type === TargetingType.MANUAL);

  // AD GROUP SELECT
  const [selectedAdGroupIds, setSelectedAdGroupIds] = useState<string[]>([]);
  // Selected campaigns' ad groups
  const adGroupSelectOptions = useMemo(() => {
    if (!campaignToAdGroupsRecord || !selectedCampaignIds) return [];

    return selectedCampaignIds.flatMap((campaignId) => {
      const campaign = campaignToAdGroupsRecord[campaignId];
      if (!campaign) return [];

      return Object.keys(campaign.adGroupIdToAdGroup)
        .map((adGroupId) => {
          const adGroup = campaign.adGroupIdToAdGroup[adGroupId];
          const type = adGroup.entityType;
          const color = TargetEntityTypeColors[type];
          return new AlMultiSelectOptionModel(adGroup.name, adGroup.id, undefined, t(`targeting_page.bidding_entity.${type}`), color);
        })
        .filter((option) => {
          const adGroup = campaign.adGroupIdToAdGroup[option.id];
          if (!isSomeKeywordsChecked && adGroup.entityType === TargetEntityType.KEYWORD) {
            return false;
          }

          if (!isSomeProductTargetsChecked && adGroup.entityType === TargetEntityType.PRODUCT_TARGET) {
            return false;
          }
          return true;
        });
    });
  }, [campaignToAdGroupsRecord, selectedCampaignIds, isSomeKeywordsChecked, isSomeProductTargetsChecked]);

  const isSomeAdGroupsKeywordType = selectedAdGroupIds
    .map((id) => adGroupIdToAdGroupRecord[id].entityType)
    .some((type) => type === TargetEntityType.KEYWORD);
  const isSomeAdGroupsProductTargetType = selectedAdGroupIds
    .map((id) => adGroupIdToAdGroupRecord[id].entityType)
    .some((type) => type === TargetEntityType.PRODUCT_TARGET);

  useEffect(() => {
    if (!campaignToAdGroupsRecord) return;
    const validAdGroupIds = new Set<string>();

    selectedCampaignIds.forEach((campaignId) => {
      const campaign = campaignToAdGroupsRecord[campaignId];
      if (campaign) {
        Object.keys(campaign.adGroupIdToAdGroup).forEach((adGroupId) => {
          validAdGroupIds.add(adGroupId);
        });
      }
    });

    const filteredAdGroupIds = selectedAdGroupIds.filter((adGroupId) => validAdGroupIds.has(String(adGroupId)));
    if (filteredAdGroupIds.length !== selectedAdGroupIds.length) {
      setSelectedAdGroupIds(filteredAdGroupIds);
    }
  }, [selectedCampaignIds, campaignToAdGroupsRecord]);

  // FIELD VISIBILITY
  const isSelectCampaignsVisible = isSomeKeywordsChecked || isSomeProductTargetsChecked || !isNil(preset?.sourceCampaignIds);
  const isSelectAdGroupsVisible = selectedCampaignIds.length > 0 && isSomeAdGroupsChecked;
  const isSelectNegativeKeywordsVisible =
    selectedCampaignIds.length > 0 && isSomeKeywordsChecked && !(!isSomeAdGroupsKeywordType && selectedAdGroupIds.length > 0);
  const isSelectProductAsinsVisible =
    selectedCampaignIds.length > 0 &&
    isSomeProductTargetsChecked &&
    !(!isSomeAdGroupsProductTargetType && selectedAdGroupIds.length > 0) &&
    !(isOnlyManualCampaignsChosen && selectedAdGroupIds.length === 0) &&
    !(
      negativeMatchOptions.adGroupNegativeProductTarget &&
      !negativeMatchOptions.campaignNegativeProductTarget &&
      selectedAdGroupIds.length == 0
    );

  // CONFIRMATION
  const [isApplying, setIsApplying] = useState(false);

  async function onCreate(negativeKeywords: CreateNegativeModel[]) {
    const response = await negativeTargetingService.createNegativeKeywords(negativeKeywords);

    if (response.isSuccess) {
      onApplied({ responseErrorMsg: null, payload: response.payload.created });

      // Sleep a bit so backend has time to update
      setTimeout(() => {
        if (activeProfile) {
          invalidateProfile_negativeTargetingQueryKeys(queryClient, activeProfile.id);
        }
      }, 1500);
    } else {
      onApplied({ responseErrorMsg: `Did not receive a response from server: ${response.message}`, payload: undefined });
    }
  }

  const onConfirmActionClicked = () => {
    setIsApplying(true);

    if (negativeKeywordsToCreate.length > 0) {
      onCreate(negativeKeywordsToCreate);
    } else {
      toast.warn('No negatives to create');
    }

    onClose();
    setIsApplying(false);
  };

  function onCancelClicked() {
    setIsApplying(false);
    onClose();
  }

  useEffect(() => {
    // Create temp selected search terms array to use existing negative creation flow
    const selectedSearchTerms = SearchTermModel.selectedSearchTermFromNegativesData({
      selectedCampaignIds: isSelectCampaignsVisible ? selectedCampaignIds : [],
      selectedAdGroupIds: isSelectAdGroupsVisible ? selectedAdGroupIds : [],
      selectedNegativeKeywords: isSelectNegativeKeywordsVisible ? selectedNegativeKeywords : [],
      selectedNegativeProductTargetAsins: isSelectProductAsinsVisible ? selectedNegativeProductTargetAsins : [],
      campaignToAdGroupsRecord,
    });

    setNegativeKeywordsToCreate(CreateNegativeModel.fromSelectedSearchTermArrayAndParams(selectedSearchTerms, negativeMatchOptions));
  }, [selectedCampaignIds, selectedAdGroupIds, selectedNegativeKeywords, selectedNegativeProductTargetAsins]);

  const warnings = preset
    ? [
        warningsIndicators.campaignCountFilteredOutDueNoAdGroups > 0 &&
          !warningsIndicators.presetNoCampaignsLeftAfterEmptyAdGroupRemoved &&
          `${warningsIndicators.campaignCountFilteredOutDueNoAdGroups} Campaign(s) filtered out due to having no ad groups`,
        warningsIndicators.presetNoCampaignsLeftAfterEmptyAdGroupRemoved && 'None of the selected campaigns have any ad groups',
        warningsIndicators.notAllNegativesCanBeMadeForSelectedCampaigns && 'Not all selected negatives can be made for selected campaigns',
      ].filter(Boolean)
    : null;

  // Preview component of what will be created
  function negativeKeywordsToCreateToString(neg: CreateNegativeModel): string {
    const campaignOrAdGroupName = CAMPAIGN_NEGATIVES.includes(neg.negativeMatchType) ? neg.campaignName : neg.adGroupName;
    // Add bullet in front
    return `\u2022 ${neg.searchTerm} - ${t(`optimizer_page.negative_match_type.${neg.negativeMatchType}`)} - ${campaignOrAdGroupName}`;
  }

  const theme = useTheme();
  const negativesToCreateComponent = useMemo(() => {
    if (negativeKeywordsToCreate.length === 0) return null;

    return (
      <Tooltip
        title={createMultilineMessageComponent(
          negativeKeywordsToCreate.map((n) => negativeKeywordsToCreateToString(n)),
          6,
          1,
        )}
      >
        <div className="flex flex-row ml-3">
          <InfoOutlinedIcon fontSize="small" style={{ marginRight: '8px', color: theme.palette.info.main }} />
          <Typography variant="body2">This will create {negativeKeywordsToCreate.length} negative targets</Typography>
        </div>
      </Tooltip>
    );
  }, [negativeKeywordsToCreate]);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle style={{ paddingBottom: '0px' }}>Create Negative Targets</DialogTitle>
      <DialogContent>
        {/* Checkboxes */}
        <div className="flex justify-between p-4 items-center">
          <div>
            <Typography variant="h6">Campaign Negatives</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.campaignNegativeExact}
                    onChange={handleNegativeMatchOptionsChange}
                    name="campaignNegativeExact"
                  />
                }
                label="Negative Exact"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.campaignNegativePhrase}
                    onChange={handleNegativeMatchOptionsChange}
                    name="campaignNegativePhrase"
                  />
                }
                label="Negative Phrase"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.campaignNegativeProductTarget}
                    onChange={handleNegativeMatchOptionsChange}
                    name="campaignNegativeProductTarget"
                  />
                }
                label="Negative Product Target"
              />
            </FormGroup>
          </div>
          <div>
            <Typography variant="h6">Ad Group Negatives</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.adGroupNegativeExact}
                    onChange={handleNegativeMatchOptionsChange}
                    name="adGroupNegativeExact"
                  />
                }
                label="Negative Exact"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.adGroupNegativePhrase}
                    onChange={handleNegativeMatchOptionsChange}
                    name="adGroupNegativePhrase"
                  />
                }
                label="Negative Phrase"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={negativeMatchOptions.adGroupNegativeProductTarget}
                    onChange={handleNegativeMatchOptionsChange}
                    name="adGroupNegativeProductTarget"
                  />
                }
                label="Negative Product Target"
              />
            </FormGroup>
          </div>
        </div>
        {/* Dropdowns */}
        <div className="flex flex-col gap-4 items-center">
          {isSelectCampaignsVisible && (
            <AlMultiSelect
              options={campaignSelectOptions}
              selectedOptionIds={selectedCampaignIds}
              setSelectedOptionIds={setSelectedCampaignIds}
              label="Campaign(s)"
              placeholderText="Select Campaign(s)"
              isDisabled={!isNil(preset?.sourceCampaignIds)}
            />
          )}

          {isSelectAdGroupsVisible && (
            <AlMultiSelect
              options={adGroupSelectOptions}
              selectedOptionIds={selectedAdGroupIds}
              setSelectedOptionIds={setSelectedAdGroupIds}
              label="Ad Group(s)"
              placeholderText="Select Ad Group(s)"
              isDisabled={selectedCampaignIds.length === 0}
            />
          )}

          {isSelectNegativeKeywordsVisible && (
            <AlMultiTextInput label="Negative Keyword(s)" tags={selectedNegativeKeywords} setTags={setSelectedNegativeKeywords} />
          )}

          {isSelectProductAsinsVisible && (
            <AlMultiTextInput
              label="Negative Product ASIN(s)"
              tags={selectedNegativeProductTargetAsins}
              setTags={setNegativeProductTargetAsinsWithValidationAndFormatting}
            />
          )}
        </div>
      </DialogContent>
      <Divider />
      {warnings && (
        <div className="flex flex-col mx-5">{warnings?.map((warning, index) => <InfoMessage key={index} text={warning as string} />)}</div>
      )}

      <DialogActions>
        {negativesToCreateComponent}
        <Box flexGrow={1} />
        <Button onClick={onCancelClicked} variant="text">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isApplying || isCampaignToAdGroupsRecord}
          onClick={onConfirmActionClicked}
          endIcon={<SendIcon />}
          disabled={
            (!isSomeKeywordsChecked && !isSomeProductTargetsChecked) ||
            selectedCampaignIds.length === 0 ||
            (isSomeKeywordsChecked && selectedNegativeKeywords.length === 0) ||
            (isSomeProductTargetsChecked && selectedNegativeProductTargetAsins.length === 0) ||
            (isSomeAdGroupsChecked && selectedAdGroupIds.length === 0)
          }
        >
          Add Negatives
        </LoadingButton>
      </DialogActions>
      <LoadingOverlay isVisible={isApplying} message="Sending new data to Amazon..." />
    </Dialog>
  );
};
