import useFormatting from '@/hooks/useFormatting';
import { UpdateResponseDTO, UpdateResponseModal } from '@/modules/application/components/UpdateResponseModal';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Alert, Button } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { NegativeTargetingResponse } from '../models/NegativeTargetsModel';
import { CreateNegativesDirectModal } from './add-negatives/CreateNegativesDirectModal';

interface NegativeTargetingActionsProps {
  negativeTargetsData: NegativeTargetingResponse | undefined;
}

const NegativeTargetingActions: FunctionComponent<NegativeTargetingActionsProps> = ({ negativeTargetsData }) => {
  const { formatWithThousandsSeparator } = useFormatting();

  const [isCreateNegativesDirectModalOpen, setIsCreateNegativesDirectModalOpen] = useState(false);

  const sentKeywordsCount = negativeTargetsData?.stats?.sentKeywords ?? 0;
  const sentTargetsCount = negativeTargetsData?.stats?.sentTargets ?? 0;
  const totalSent = sentKeywordsCount + sentTargetsCount;
  const totalKeywords = negativeTargetsData?.stats?.totalKeywords ?? 0;
  const totalTargets = negativeTargetsData?.stats?.totalTargets ?? 0;
  const allTotal = totalKeywords + totalTargets;

  const statsString = `Showing ${formatWithThousandsSeparator(totalSent)} of ${formatWithThousandsSeparator(allTotal)} negative targets`;

  // Creation
  const [updateResponseModalOpen, setUpdateResponseModalOpen] = useState(false);
  const [applyResponse, setApplyResponse] = useState<UpdateResponseDTO>();

  function onApplied(applyResponse: React.SetStateAction<UpdateResponseDTO | undefined>) {
    setApplyResponse(applyResponse);
    setUpdateResponseModalOpen(true);
  }

  return (
    <>
      <div className="flex flex-row mb-3 mt-3 gap-x-2 items-center">
        <Button
          size="small"
          className="whitespace-nowrap"
          onClick={() => setIsCreateNegativesDirectModalOpen(true)}
          startIcon={<RemoveCircleOutlineIcon />}
          variant="contained"
        >
          Add Negatives
        </Button>

        <Alert severity="info" icon={false}>
          {statsString}
        </Alert>
      </div>

      <CreateNegativesDirectModal
        isOpen={isCreateNegativesDirectModalOpen}
        onClose={() => setIsCreateNegativesDirectModalOpen(false)}
        onApplied={onApplied}
      />
      <UpdateResponseModal
        isOpen={updateResponseModalOpen}
        onClose={() => setUpdateResponseModalOpen(false)}
        updateResponse={applyResponse}
      />
    </>
  );
};

export default NegativeTargetingActions;
