import { DASHBOARDS_TRANSLATIONS_ENGLISH } from '@/modules/dashboards/translations/en';
import { DATA_MANAGEMENT_TRANSLATIONS_ENGLISH } from '@/modules/data-management/translations/en';
import { optimizerTranslationsEnglish } from '@/modules/optimizer/translations/en';
import { ResourceLanguage } from 'i18next';

export const enTranslations: ResourceLanguage = {
  translation: {
    ...optimizerTranslationsEnglish,
    ...DASHBOARDS_TRANSLATIONS_ENGLISH,
    ...DATA_MANAGEMENT_TRANSLATIONS_ENGLISH,
  },
};
