import { ApiResponse } from '@/lib/api/api-response';
import { apiClient, apiProfileClient } from '@/lib/api/base-client';
import { invalidateAll_campaignsWithTimelineQueryKeys } from '@/modules/optimizer/api/campaign/campaign-service';
import { invalidateAll_placementsWithTimelineQueryKeys } from '@/modules/placements/api/placements-service';
import { invalidateAll_productsWithTimelineQueryKeys } from '@/modules/products/api/products-service';
import { invalidateAll_searchTermsWithTimelineQueryKeys } from '@/modules/search-terms/api/search-terms-service';
import { invalidateAll_targetingWithTimelineQueryKeys } from '@/modules/targeting/api/targets-service';
import { QueryClient } from '@tanstack/react-query';
import { DataGroupModel } from '../models/DataGroup';
import { NewDataItem } from '../models/DataItem';
import { DataGroupDTO, DataGroupType, DeletedAssignment, EntityIdAndType, UpdatableDataItemDTO } from '../models/data-groups-contracts';

export const createGetAllGroupsQueryKey = (activeTeamId: number | undefined) => ['data_groups', 'getAllGroups', activeTeamId];
export const createGetAllGroupsForMultipleTeamsQueryKey = (activeTeamIds: number[]) => ['data_groups', 'getAllGroups', ...activeTeamIds];

export function invalidateTeam_getAllGroups(queryClient: QueryClient, activeTeamId: number | undefined) {
  const key = createGetAllGroupsQueryKey(activeTeamId);
  queryClient.invalidateQueries({
    predicate: (query) => key.every((keyPart, index) => query.queryKey[index] === keyPart),
  });
}

export class DataGroupsService {
  public basePath = 'datagroups';

  async getAllGroups(): Promise<ApiResponse<DataGroupModel[]>> {
    const applicationResponse = await apiProfileClient.get<DataGroupDTO[]>(`${this.basePath}`);
    return applicationResponse.processPayload(DataGroupModel.fromResponseArray);
  }

  async getAllGroupsForProfile(teamId: number, profileId: string): Promise<ApiResponse<DataGroupModel[]>> {
    const applicationResponse = await apiClient.get<DataGroupDTO[]>(`/teams/${teamId}/profiles/${profileId}/${this.basePath}`);
    return applicationResponse.processPayload(DataGroupModel.fromResponseArray);
  }

  async createGroup(dataGroup: DataGroupModel): Promise<ApiResponse<unknown>> {
    const payload = {
      data_group: DataGroupModel.toDTO(dataGroup),
    };

    return await apiProfileClient.post<DataGroupDTO>(`${this.basePath}/create`, payload);
  }

  async updateGroup(dataGroup: DataGroupModel, newItems?: NewDataItem[]): Promise<ApiResponse<unknown>> {
    // Using UpdatableDataItemDTO because id for items is optional (new items don't have id)
    const items = dataGroup.items.map((item) => ({ id: item.id, name: item.name }) as UpdatableDataItemDTO);

    const payload = {
      data_group: {
        id: dataGroup.id,
        name: dataGroup.name,
        type: dataGroup.type,
        items: items.concat(newItems || []),
      },
    };

    return await apiProfileClient.post<DataGroupDTO>(`${this.basePath}/update`, payload);
  }

  async deleteItems(dataItemIds: number[]): Promise<ApiResponse<unknown>> {
    const payload = {
      item_ids: dataItemIds,
    };

    return await apiProfileClient.post<DataGroupDTO>(`${this.basePath}/delete`, payload);
  }

  async deleteGroup(dataGroupId: number): Promise<ApiResponse<unknown>> {
    const payload = {
      group_ids: [dataGroupId],
    };

    return await apiProfileClient.post<DataGroupDTO>(`${this.basePath}/delete`, payload);
  }

  async assignItemToEntities(entities: EntityIdAndType[], dataGroupId: number, dataItemId: number): Promise<ApiResponse<unknown>> {
    const payload = {
      entities: entities,
      group_id: dataGroupId,
      item_id: dataItemId,
    };

    return await apiProfileClient.post<DataGroupDTO>(`${this.basePath}/assign`, payload);
  }

  async removeAssignments(assignments: DeletedAssignment[]): Promise<ApiResponse<unknown>> {
    const payload = {
      assignments: assignments,
    };

    return await apiProfileClient.post<DataGroupDTO>(`${this.basePath}/delete-assign`, payload);
  }

  invalidateEntitiesContainingDataGroupsByType(dataGroupType: DataGroupType, queryClient: QueryClient) {
    switch (dataGroupType) {
      case DataGroupType.CAMPAIGN:
        invalidateAll_campaignsWithTimelineQueryKeys(queryClient);
        invalidateAll_searchTermsWithTimelineQueryKeys(queryClient);
        invalidateAll_targetingWithTimelineQueryKeys(queryClient);
        invalidateAll_placementsWithTimelineQueryKeys(queryClient);
        break;
      case DataGroupType.TARGET:
        invalidateAll_targetingWithTimelineQueryKeys(queryClient);
        invalidateAll_searchTermsWithTimelineQueryKeys(queryClient);
        break;
      case DataGroupType.SEARCHTERM:
        invalidateAll_searchTermsWithTimelineQueryKeys(queryClient);
        break;
      case DataGroupType.PRODUCT:
        invalidateAll_productsWithTimelineQueryKeys(queryClient);
        break;
      default:
        // This will cause a compile-time error if any case is not handled
        const exhaustiveCheck: never = dataGroupType;
        throw new Error(`Unhandled data group type: ${exhaustiveCheck}`);
    }
  }
}

export const dataGroupsService = new DataGroupsService();
